import React from 'react';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Box,
  makeStyles,
  Theme
} from '@material-ui/core';

interface ProcessingDialogProps {
    open: boolean;
  }

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    minWidth: 350,
    minHeight: 200,
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  loadingText: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary,
  },
}));

export default function ProcessingDialog({ open }: ProcessingDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialogPaper }}
      disableEscapeKeyDown
      onClose={(event: React.SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
        // Prevent closing dialog on backdrop click
        if (reason === 'backdropClick') {
          return;
        }
      }}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress 
            size={60} 
            thickness={3.5}
            color="primary" 
          />
          <Typography
            variant="h6"
            className={classes.loadingText}
          >
            Probíhá zpracování objednávky. Po dokončení budete automaticky přesměrování.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};