import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  Theme,
  FormControlLabel,
  Switch,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import CookiesConsentState  from '../Types/cookies';
import { useCookies } from 'react-cookie';
import { useLayout } from '../Layout/LayoutContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4, 0),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    textAlign: "justify"
  },
  section: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  switchLabel: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
}));

export default function PrivacySettings() {
    const classes = useStyles();
    const theme = useTheme();
    const layout = useLayout();
    const [cookiesConsent, setCookiesConsent] = useState<CookiesConsentState>({
        necessary: true,
        marketing: false
        });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_cookies, setCookie] = useCookies(['necessary', 'marketing']);
    
    const handleSwitchChange = (name: keyof CookiesConsentState) => 
        (event: React.ChangeEvent<HTMLInputElement>) => {
        setCookiesConsent({ ...cookiesConsent, [name]: event.target.checked });
    };
    
    const handleSaveSettings = () => {
        const cookieOptions = {
            path: '/',
            maxAge: 15768000, // 6 mounths in seconds
        };
        
        for (const [key, value] of Object.entries(cookiesConsent)) {
            console.log(`${key}: ${value}`);
            let cookieKey = key as keyof CookiesConsentState;
            setCookie(cookieKey, value, cookieOptions);
        }
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Grid container direction="column" className={classes.paper}>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                    Nastavení soukromí
                    </Typography>
                </Grid>   
                <Grid item>
                    <Typography variant="body2" color="textSecondary" style={{ marginBottom: 24, textAlign: "justify" }}>
                    Zde si můžete upravit nastavení cookies a personalizace. Změny nastavení se projeví pouze
                    pro prohlížeč a zařízení, které právě používáte.
                    </Typography>
                </Grid>

                {/* Necessary Cookies Section */}
                <Grid item>
                    <Paper variant="outlined" className={classes.section}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item  xs={10} sm={10} lg={11}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Cookies nezbytné pro fungování webu
                                </Typography>
                                
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: 24, textAlign: "justify" }}>
                                Některé soubory cookies jsou vyžadovány, aby byla zajištěna základní funkčnost. V našem případě slouží k umožnění přihlášení
                                k Vašemu registrovanému účtu. Ve výchozím nastavení jsou povoleny a nelze je zakázat.
                                </Typography>
                            </Grid>
                            <Grid container xs={2} sm={2} lg={1} justifyContent="flex-end">
                                <FormControlLabel
                                    control={
                                        <Switch 
                                            checked 
                                            disabled 
                                            color="primary" />}
                                    label=""
                                    className={classes.switchLabel}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Marketing Cookies Section */}
                <Grid item>
                    <Paper variant="outlined" className={classes.section}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} lg={11}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Marketingové cookies
                                </Typography>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: 24, textAlign: "justify" }} >
                                Spolupracujeme s externími partnery Heureka.cz a Zbozi.cz. K měření výkonu reklamy na Zbozi.cz a Heureka.cz používáme soubory cookie,
                            které napomáhají doměřování konverzí. V případě udělení souhlasu jsou dodatečně zasílaný Vaše údaje (email, adresa, telefon) v zašifrované podobě.
                                </Typography>
                            </Grid>
                            <Grid container xs={2} sm={2} lg={1} justifyContent="flex-end">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={cookiesConsent.marketing}
                                            onChange={handleSwitchChange("marketing")}
                                            color="primary"
                                        />
                                    }
                                    label=""
                                    className={classes.switchLabel}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Saving button */}
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleSaveSettings();
                            layout.info("Nastavení cookies bylo uloženo")
                        }}
                        style={{
                            borderRadius: 20,
                            textTransform: 'none',
                            boxShadow: theme.shadows[2],
                        }}
                    >
                    Uložit nastavení
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}