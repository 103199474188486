interface FetchOptions {
  success: (data: any) => void;
  error: (message: string) => void;
}

const formatUrl = (url: string, params?: { [index: string]: any }) => {
  if (params === undefined) return url;
  return `${url}?${Object.keys(params)
    .filter((k) => params[k] !== null && params[k] !== undefined)
    .map((k) => {
      if (Array.isArray(params[k])) {
        return params[k].map((p: any) => `${k}=${encodeURIComponent(p.toString())}`).join('&');
      }
      return `${k}=${encodeURIComponent(params[k].toString())}`;
    })
    .join('&')}`;
};

class ApiService {
  private _apiUrl: string = '';

  constructor(apiUrl: string) {
    this._apiUrl = apiUrl;
  }

  public async get<T>(url: string, params?: { [index: string]: any }, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return basicFetch<T>(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async post<T>(url: string, params: { [index: string]: any }, data?: any, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("QR-APIAccessToken") === null ? "" : `Bearer ${localStorage.getItem("QR-APIAccessToken")}`,
      },
      body: JSON.stringify(data),
    };
    return basicFetch<T>(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async put(url: string, params?: { [index: string]: any }, data?: any, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("QR-APIAccessToken") === null ? "" : `Bearer ${localStorage.getItem("QR-APIAccessToken")}`,
      },
      body: JSON.stringify(data),
    };

    basicFetch(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async delete(url: string, params?: { [index: string]: any }, data?: any, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const initReq: RequestInit = {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        // Authorization:
        //   localStorage.getItem("QR-APIAccessToken") === null ? "" : `Bearer ${localStorage.getItem("QR-APIAccessToken")}`,
      },
      body: JSON.stringify(data),
    };

    basicFetch(initReq, `${this._apiUrl}${url}`, params, options);
  }

  public async uploadFile(url: string, file: File, params?: { [index: string]: any }, options?: FetchOptions) {
    if (url.startsWith('/')) url = url.substring(1);
    const formData = new FormData();
    formData.append('file', file);
    fetch(formatUrl(`${this._apiUrl}${url}`, params), {
      method: 'post',
      body: formData,
    }).then((res) => {
      if (res.ok) {
        res
          .text()
          .then((t) => options?.success(t))
          .catch(() => options?.error(''));
      } else {
        options?.error(res.statusText);
      }
    });
  }
}

function basicFetch<T>(initReq: RequestInit, url: string, params?: { [index: string]: any }, options?: FetchOptions) {
  if (options === undefined) options = { error: () => {}, success: () => {} };

  return new Promise<T>(async (resolve, reject) => {
    fetch(formatUrl(url, params), initReq)
    .then((res) => {
      if (res.ok) {
        const contentLength = res.headers.get('Content-Length');
        if (contentLength === '0' || res.status === 204) {
          return Promise.resolve(null); // No body to parse
        } else {
          return res.json();
        } 
      } else {
        const errorMessage = `${res.statusText} [${res.status}]`;
        options?.error(errorMessage);
        return Promise.reject(errorMessage);
      }
    })
    .then((result: T) => {
      options?.success(result);
      resolve(result);
    })
    .catch((error) => {
      console.error('Error during fetch:', error);
      options?.error('JSON serialization failed: ' + error);
      reject(error);
    });
  });
}

const apiUrl = 'https://api.magistrmartin.cz/';

const ordersService = new ApiService(apiUrl + 'orders/');
const catalogService = new ApiService(apiUrl + 'catalog/');
const profilesService = new ApiService(apiUrl + 'profiles/');
const webStructureService = new ApiService(apiUrl + 'structure/');
const imagesService = new ApiService(apiUrl + 'images/');
const statisticsService = new ApiService(apiUrl + 'statistics/');
const rest = new ApiService('');

export {
  ordersService,
  catalogService,
  profilesService,
  webStructureService,
  imagesService,
  statisticsService,
  apiUrl,
  rest,
};
