import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import EText from './EText';
import env from '../Environments';
import { useHistory } from 'react-router-dom';
import CartButton from './CartButton';
import { round, separateThousands } from '../Utils/Common';
import LabelsContainer from './LabelsContainer';
import { useLayout } from '../Layout/LayoutContext';
import { getPrice } from '../Utils/Product';
import Environment from '../Environments';
import { usePickupPlaces } from '../Hooks/PickupPlaces';
import { catalogService } from '../Utils/ApiService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      border: '2px solid ' + theme.palette.primary.main,
      margin: 22,
      cursor: 'pointer',
    },
    header: {
      paddingTop: 22,
      paddingBottom: 10,
      backgroundColor: theme.palette.primary.main,
    },
    body: {
      padding: 12,
    },
    logo: {
      width: '100%',
    },
    preview: {
      width: '100%',
    },
    availableText: {
      fontWeight: 'bold',
      color: 'green',
    },
    unavailableText: {
      fontWeight: 'bold',
      color: 'red',
    },
    stockAvailabilityContainer: {
      marginTop: 8,
      width: '100%',
      textAlign: 'center',
    },
    priceContainer: {
      borderTop: '3px solid ' + theme.palette.primary.main,
      borderBottom: '3px solid ' + theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'column',
      padding: 12,
    },
    priceRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cartContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 12,
      paddingTop: 16,
    },

    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: 12,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  })
);

interface IProps {
  product?: IProduct;
  advantages?: string;
  priceType: string;
  originalUnitPrice: number;
  isCombination: boolean;
}

export default function AlternativeMagistrRecommendsCard({
  product,
  advantages,
  priceType,
  originalUnitPrice,
  isCombination,
}: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const layout = useLayout();
  const { data: pickupPlaces = [] } = usePickupPlaces();

  const price = product ? getPrice(product, layout.homeConfig.todaysSpecial) : 0;
  const unitPrice = price / (product?.ammount || 1);
  const [minimalPriceIn30Days, setMinimalPriceIn30Days] = useState<number>(0);
  const availablePlaces = pickupPlaces.filter(
    (p) => (product?.stockState?.find((ss) => ss.placeId === p.id)?.ammount || 0) > 0
  );

  useEffect(() => {
    if (product) {
      catalogService.get(
        "products/noauth/productPriceHistory",
        {
          id: product.id,
          priceType: priceType,
        },
        {
          success: (response) => {
            if (response.price != null) {
              setMinimalPriceIn30Days(response.price);
            }
          },
          error: () => layout.error('Při načítání historie cen došlo k chybě'),
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id]);


  return (
    (product && (
      <div
        className={classes.root}
        onClick={() =>
          history.push(
            `/product/${product.title?.replace(/%/g, '')?.replace(/\//g, '')?.replace(/[ ]/g, '-')}-${product.subtitle
              ?.replace(/\//g, '')
              ?.replace(/%/g, '')
              ?.replace(/[ ]/g, '-')}-${product.id}`
          )
        }
      >
        <div className={classes.header}>
          {isCombination ? (
            <EText color="contrast" align="center" variant="h4">
              Kombinujte s
            </EText>
          ) : (
            <>
              <EText color="contrast" align="center" variant="h4">
                ALTERNATIVA
              </EText>
              <EText color="contrast" align="center">
                <b>{Environment.fullName.toUpperCase()} &nbsp; DOPORUČUJE</b>
              </EText>
            </>
          )}
        </div>
        <div className={classes.body}>
          <br />
          <LabelsContainer product={product} showBottomDescription={false} labelsSize={'sm'} minPrice30Days={minimalPriceIn30Days}>
            <img src={(product.images || [''])[0]} alt="Magistr Martin reccomend preview" className={classes.preview} />
          </LabelsContainer>
          {!isCombination && (
            <>
              <EText>
                <b>Výhody oproti originálu</b>
              </EText>
              <EText variant="body1">{advantages}</EText>
              <br />
            </>
          )}
          <EText color="secondary" variant="h6">
            {product.title}
          </EText>
          <EText color="secondary" variant="subtitle1">
            {product.subtitle}
          </EText>
          <br />
        </div>
        <div className={classes.priceContainer}>
            <div>
              <div className={classes.priceRow}>
                <EText variant="h5">
                  <b>{separateThousands(round(price || 0, 2))}&nbsp;Kč</b>&nbsp;
                  {price < (minimalPriceIn30Days || 0) && (
                  <span>
                    <Typography
                      noWrap
                      display="inline"
                      style={{ textDecoration: 'line-through' }}
                      align="center"
                      color="secondary"
                      variant="h6"
                      >
                      {separateThousands(round(minimalPriceIn30Days || 0, 2))}
                      &nbsp;Kč   
                    </Typography>
                  </span>
                  )}
                </EText>
              </div>
              <div className={classes.priceRow}>
              <EText variant="caption">
                  {separateThousands(round(unitPrice || 0, 2))}&nbsp;Kč/
                  {product.unit}
                  {!isCombination && unitPrice < originalUnitPrice && (
                    <>
                      &nbsp;-&nbsp;levnější&nbsp;o&nbsp;
                      {separateThousands(round((1 - unitPrice / originalUnitPrice) * 100, 0))}
                      &nbsp;%
                    </>
                  )}
                </EText>
              </div>
            </div>
            <div>
            <div>
            {price < (minimalPriceIn30Days || 0) && (
              <EText style={{color: "grey", fontSize: "0.8rem"}}>Minimální prodejní cena za posledních 30 dní:&nbsp; 
                {price === minimalPriceIn30Days 
                ? separateThousands(minimalPriceIn30Days) + " Kč"
                : separateThousands(minimalPriceIn30Days) + " Kč - sleva: " + (100 - Math.ceil(price * 100 / minimalPriceIn30Days)) + " %"
                }
              </EText>
            )}
            </div>
          </div>
        </div>
        <div className={classes.cartContainer}>
          <span className={classes.flexRow}>
            <CartButton onClick={(e) => e.stopPropagation()} product={product} />
          </span>
          <div className={classes.stockAvailabilityContainer}>
            {
              product.unavailable 
              ? 
                <EText className={classes.unavailableText} style={{ color: 'red' }}>
                  Dočasně nedostupné na e-shopu
                </EText>
              : 
                (
                  (product.stockAmmount || 0) > 0 || 
                  ((product.expiration || '') !== '' && new Date(product.expiration || '').getFullYear() > 2000) || 
                  (product.stockState && product.stockState.some(item => item.ammount > 0)) ||
                  (product.pharmosStockState && product.pharmosStockState > 0)
                ) ?
                  <EText className={classes.availableText} style={{ color: 'green' }}>
                    Skladem na e-shopu
                  </EText>
                  :
                  <EText className={classes.unavailableText} style={{ color: 'red' }}>
                    Dočasně nedostupné na e-shopu
                  </EText>
            }
            {env.showReservation && (
              <>
                {product.pharmasStock && availablePlaces.length > 0 ? (
                  <EText style={{ fontSize: 12, color: 'green' }}>
                    K rezervaci na {availablePlaces.length} {availablePlaces.length > 1 ? 'lékárnách' : 'lékárně'}
                  </EText>
                ) : (
                  <EText style={{ fontSize: 12, color: 'green' }}>&nbsp;</EText>
                )}
              </>
            )}
          </div>
          <br />
          <EText variant="caption" align="center">
            {product.characteristics}
          </EText>
        </div>
      </div>
    )) || <></>
  );
}
