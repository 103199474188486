import React, { MouseEvent, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, useTheme, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import AlternativeDialog from './AlternativeDialog';
import useCart, { CartState } from '../Stores/cartStore';
import { usePickupPlaces } from '../Hooks/PickupPlaces';

interface IStyleProps {
  disabled: boolean;
  fullWidth?: boolean;
  color?: string;
}

const useStyles = (props: IStyleProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: theme.palette.primary.contrastText,
        fontFamily: 'Roboto',
        width: 'fit-content',
        padding: 16,
        borderRadius: 8,
        textAlign: 'center',
      },
      button: {
        backgroundColor: props.disabled
          ? 'lightGrey'
          : props.color === 'secondary'
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
        cursor: props.disabled ? 'initial' : 'pointer',
        width: props.fullWidth ? 'calc(100% - 32px)' : 'fit-content',
        '&:hover': {
          filter: props.disabled ? '' : 'brightness(90%)',
        },
      },
    })
  );

interface IProps {
  className?: string;
  ammount?: number;
  product?: IProduct;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  fullWidth?: boolean;
  alternative?: IProduct;
  // alternativeId?: number;
  isRecursive?: boolean;
  minPriceIn30Days?: number;
  priceType?: string;
}

export default function CartButton({
  className,
  onClick,
  fullWidth,
  product,
  ammount,
  color,
  disabled: disabledRaw,
  alternative: alternativeParam,
  priceType,
  minPriceIn30Days,
  isRecursive,
}: // alternativeId,
IProps) {
  const [alternativeModalOpen, setAlternativeModalOpen] = useState(false);
  const [alternative, setAlternative] = useState<IProduct | undefined>(undefined);
  const layout = useLayout();
  const addProductToCart = useCart((state: CartState) => state.addProduct);
  const theme = useTheme();
  const disabled = disabledRaw || layout.homeConfig.disableCart === true;
  const { data: pickupPlaces = [] } = usePickupPlaces();
  const classes = useStyles({
    disabled: (disabled || product?.unavailable) ?? false,
    fullWidth: fullWidth,
    color: color,
  })();

  useEffect(() => {
    if (isRecursive === true) setAlternative(undefined);
    else if (alternativeParam !== undefined) setAlternative({ ...alternativeParam });
    else if (
      product?.reccomendedAlternative?.alternativeId !== undefined &&
      product?.reccomendedAlternative?.alternativeId !== 0
    ) {
      catalogService.get(
        '/products/noauth',
        { id: product?.reccomendedAlternative?.alternativeId, mapped: true },
        {
          success: setAlternative,
          error: console.log,
        }
      );
    } else {
      setAlternative(undefined);
    }
  }, [alternativeParam, product?.reccomendedAlternative?.alternativeId, isRecursive]);



  return (
    <>
      <Button
        className={`${classes.root} ${classes.button} ${className || ''}`}
        variant="contained"  // or 'outlined', 'text' based on your design
        color="primary"      // or any color from your theme
        disabled={disabled || product === undefined || product.unavailable || product.isRx}
        onClick={(e) => {
          if (product && product.unavailable) {
            layout.warning('Produkt ve výpadku není možné dát do košíku');
          } else if (alternative === undefined) {
            addProductToCart(product as IProduct, ammount);
            layout.customAlert(
              `Produkt ${product?.title} byl přidán do košíku`,
              theme.palette.secondary.main,
              theme.palette.secondary.contrastText
            );
          } else {
            setAlternativeModalOpen(true);
          }
          e.stopPropagation();

        }}
      >
        Do košíku
        <FontAwesomeIcon icon={faCartPlus} />
      </Button>
      {product && alternative && (
        <AlternativeDialog
          alternativeModalOpen={alternativeModalOpen}
          setAlternativeModalOpen={setAlternativeModalOpen}
          product={product}
          alternative={alternative}
          pickupPlaces={pickupPlaces}
          priceType={priceType || ''}
          minPriceIn30Days={minPriceIn30Days ?? 0}
        />
      )}
    </>
  );
}
