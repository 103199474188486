import React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import PlaceSelectMap from './PlaceSelectMap';
import Environment from '../Environments';
import EText from './EText';
import { usePickupPlaces } from '../Hooks/PickupPlaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: '8px',
      //   marginTop: 32,
      marginBottom: 32,
      cursor: 'pointer',
      alignItems: 'center',
    },
    title: {
      color: 'white',
      fontFamily: 'Roboto',
      marginBottom: 4,
    },
    subtitle: {
      color: 'white',
      fontFamily: 'Roboto',
      fontSize: '0.9rem',
      marginTop: 2,
    },
    icon: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      height: 36,
      width: 48,
      minWidth: 48,
      textAlign: 'center',
      marginLeft: 18,
      marginRight: 18,
      // marginTop: 20,
      paddingTop: 12,
      fontSize: '1.2rem',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    checkIconSmall: {
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 16,
      paddingLeft: 4,
      paddingTop: 1,
      height: 19,
      width: 16,
      margin: 8,
    },
    padding:
    {
      paddingLeft: 12,
      paddingBottom: 12,
    }
  })
);

interface IParams {
  product: IProduct;
}

export default function InStockBanner({ product }: IParams) {
  const classes = useStyles();
  const { data: pickupPlaces = [] } = usePickupPlaces();
  const history = useHistory();

  const availablePlaces = pickupPlaces.filter(
    (p) => (product?.stockState?.find((ss) => ss.placeId === p.id)?.ammount || 0) > 0
  );
  const isInStock =
    product.unavailable !== true && product.isRx !== true
    &&
    (
      (product.stockAmmount || 0) > 0 || 
      ((product.expiration || '') !== '' && new Date(product.expiration || '').getFullYear() > 2000) || 
      (product.stockState && product.stockState.some(item => item.ammount > 0)) ||
    (product.pharmosStockState && product.pharmosStockState > 0)
    )

  const rx = product.isRx;

  const label = (): string => {
    if (rx) return "Produkt na recept nelze objednat online";
    if (isInStock) return "Skladem v online lékárně";
    else {
      if (product.unavailable === true) return "Produkt ve výpadku";
      else return "Není skladem v online lékárně – pracujeme na doskladnění";
    }
  }

  const text = (): string => {
    if (rx) return "Omlouváme se, ale z legislativních důvodů nelze produkt na recept objednat online."
    if (isInStock) return `${Environment.fullName} brzy odešle, u vás zpravidla 1-2 dny od odeslání.`
    else {
      if (product.unavailable === true) {
        return "Omlouváme se, ale produkt je momentálně na trhu ve výpadku a není dlouhodobě dostupný."
      }
      else return "Zboží doplňujeme pravidelně 1x denně, v případě plošného výpadku na trhu může být produkt dlouhodobě nedostupný."
    } 
  }

  return (
    <>
      {Environment.showReservation && availablePlaces.length > 0 && (
        <PlaceSelectMap
          places={availablePlaces}
          renderOpener={(open) => (
            <Button onClick={open} style={{ marginTop: 8, marginBottom: 8 }} color="secondary">
              Okamžitě k rezervaci na {availablePlaces.length} {availablePlaces.length > 1 ? 'lékárnách' : 'lékárně'}
            </Button>
          )}
        />
      )}
      <div
        className={`${classes.root} ${!isInStock ? classes.padding : ''}`}
        style={{
          backgroundColor: isInStock ? '#3ebd4f' : product.unavailable || rx === true ? 'lightgrey' : '#f28313',
          marginTop: availablePlaces.length === 0 ? 20 : undefined,
        }}
        onClick={() => history.push('/DeliveryAndPayment')}
      >
        {isInStock ? (
          <div className={classes.icon}>
            <FontAwesomeIcon color="white" icon={faBox} />
          </div>
        ) : null}
        <div>
          <p
            className={classes.title}
            style={{
              color: product.unavailable || rx === true ? '#000000' : '#FFFFFF',
            }}
          >
            <b>{label()}</b>
          </p>
          <div
            className={classes.subtitle}
            style={{
              color: product.unavailable === true || rx ? '#000000' : '#FFFFFF',
            }}
          >
            {text()}
            <br />
            <br /> Přehledně <u>Doprava a platba</u>
            <br />
            {isInStock ? (
              <div className={classes.flex}>
                <div className={classes.checkIconSmall}>✔</div>
                <EText style={{ color: 'white' }}>Přes 10 000 odběrných míst v ČR</EText>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
