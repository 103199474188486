import { DiscountType, IBaseDiscount, IMixDiscount } from '@magistrmartin/eshop-frontend-shared';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { getAllDiscounts, getPrice, isDiscountValid } from './Product';
import { IDailySpecial } from '../Types/base';
import Environment from '../Environments';

export const discountLabelColors = {
  BASIC: '#E2256F',
  SECOND_ITEM: '#45ad4e',
  SPECIAL: '#d98d45',
  PRESENT: '#55328B',
  FREE_DELIVERY: '#fad014',
  MIX: '#45c8d9',
  CROSS: '#84B517',
  CENTRAL_PRESENT: '#E2256F',
};

export const getLabelName = (dis: IBaseDiscount): string => {
  const getDiscountFactorText = (factor: string | undefined) => (factor === 'relative' ? '%' : 'Kč');
  switch (dis.type) {
    case 'BASIC': {
      return `Sleva ${dis.discount?.value} ${getDiscountFactorText(dis.discount?.factor)}`;
    }
    case 'SECOND_ITEM': {
      return `2. Kus -${dis.discount?.value} ${getDiscountFactorText(dis.discount?.factor)}`;
    }
    case 'MIX': {
      return `${dis.minimum?.value} + ${(dis as IMixDiscount).free} ZDARMA`;
    }
    case 'PRESENT': {
      return 'ZDARMA';
    }
    case 'FREE_DELIVERY': {
      return 'Doprava ZDARMA';
    }
    case 'SPECIAL': {
      return `Další sleva ${dis.discount?.value} Kč`;
    }
    case 'CROSS': {
      return `Křížová sleva ${dis.discount?.value} Kč`;
    }
    default:
      return '';
  }
};

const discountsOrder = {
  left: ['BASIC', 'SECOND_ITEM', 'MIX', 'PRESENT'] as DiscountType[],
  right: ['FREE_DELIVERY', 'SPECIAL', 'CROSS'] as DiscountType[],
  heurekaIgnore: ['SECOND_ITEM', 'MIX', 'PRESENT', 'SPECIAL', 'CROSS'],
  zboziIgnore: ['SECOND_ITEM', 'MIX', 'PRESENT', 'SPECIAL', 'CROSS'],
};

const getActiveDiscounts = (product: IProduct, todaysSpecials: IDailySpecial[], minPrice30Days?: number) => {
  const res = getAllDiscounts(product).filter((d) => isDiscountValid(d));
  if (getPrice(product, todaysSpecials) < (minPrice30Days || product.reccomendedPrice || 0)) {
    res.push({
      type: 'BASIC',
      discount: {
        value:
          (minPrice30Days || product.reccomendedPrice || 0) - getPrice(product, todaysSpecials) < 50 && (minPrice30Days || product.reccomendedPrice || 0) - getPrice(product, todaysSpecials) > 5
            ? Math.floor(100 * (1 - getPrice(product, todaysSpecials) / (minPrice30Days || (product as any).reccomendedPrice || 1)))
            : (minPrice30Days || (product as any).reccomendedPrice || 0) - getPrice(product, todaysSpecials),
        factor:
          (minPrice30Days || (product as any).reccomendedPrice || 0) - getPrice(product, todaysSpecials) < 50 && (minPrice30Days || product.reccomendedPrice || 0) - getPrice(product, todaysSpecials) > 5
            ? 'relative'
            : 'absolute',
      },
    });
  }
  if (getPrice(product, todaysSpecials) >= 1499) {
    res.push({
      type: 'FREE_DELIVERY',
    });
  }
  return res;
};

export const getLeftDiscounts = (product: IProduct, todaysSpecials: IDailySpecial[], minPrice30Days: number) =>
  getDiscountsByOrder(product, todaysSpecials, discountsOrder.left, minPrice30Days);

export const getRightDiscounts = (product: IProduct, todaysSpecials: IDailySpecial[]) =>
  getDiscountsByOrder(product, todaysSpecials, discountsOrder.right);

const removeDiscountDuplicates = (discounts: IBaseDiscount[]) => {
  // Sometimes can happen, that there are more discounts of same type like FREE_DELIVERY and we want to show it just once
  let seen: { [type: string]: boolean } = {};
  return discounts.filter((disc) => {
    if (!seen[disc.type]) {
      seen[disc.type] = true;
      return true;
    } else {
      return false;
    }
  });
};

const getDiscountsByOrder = (
  product: IProduct,
  todaysSpecials: IDailySpecial[],
  columnDiscountsOrder: DiscountType[],
  minPrice30Days?: number
) => {
  const activeDiscounts = getActiveDiscounts(product, todaysSpecials, minPrice30Days)
    .filter(
      (d) =>
        columnDiscountsOrder.includes(d.type) &&
        (
          (!Environment.useHeurekaPrices || !discountsOrder.heurekaIgnore.includes(d.type))
          && 
          (!Environment.useZboziPrices || !discountsOrder.zboziIgnore.includes(d.type))
        )
        
    )
    .sort((a, b) => columnDiscountsOrder.indexOf(a.type) - columnDiscountsOrder.indexOf(b.type));
  return removeDiscountDuplicates(activeDiscounts);
};
