import React, { useEffect, useState } from 'react';
import { Container, createStyles, Grid, makeStyles, Theme, Button, useTheme, withStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import ESelect from '../../Components/ESelect';
import EText from '../../Components/EText';
import ProductPreviewCard from '../../Components/ProductPreviewCard';
import TopProductCard from '../../Components/TopProductCard';
import TopSoldProdCard from '../../Components/TopSoldProdCard';
import { useLayout } from '../../Layout/LayoutContext';
import { ICategory, IProduct } from '@magistrmartin/eshop-frontend-shared';
import { ITreeCategory } from '../../Types/base';
import { catalogService } from '../../Utils/ApiService';
import { getKeyExtractorComparer, getKeyExtractorReversedComparer } from '../../Utils/Comparers';
import { getPrice, getUnitPrice, setCorrectVerifyLogos } from '../../Utils/Product';
import useWindowDimensions from '../../Hooks/GetWindowDimenstions';
import { loadCategories } from '../../Utils/CategoriesService';
import HeadMap from '../../Components/HeadMap';
import { useHistory, useLocation } from 'react-router-dom';
import { qualityLabels } from '../../Utils/QualityLabels';
import AgeVerification from '../../Components/AgeVerification';
import Environment from '../../Environments';
import CategoriesSuggestion from './CategoriesSuggestion';
import SearchedCategories from './SearchedCategories';

const RightPagination = withStyles({
  ul: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
})(Pagination);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer',
    },
    sortRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    flexGrow: {
      flexGrow: 1,
    },
    center: {
      width: '100%',
      textAlign: 'center',
    },
    productNotFoundPanel: {
      padding: 16,
      borderRadius: 8,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#84B517',
      color: '#ffffff',
    },
    fakeProduct: {
      width: 'calc(100% - 32px)',
      border: '2px solid #ebedf0',
      cursor: 'pointer',
      height: 'calc(100% - 32px)',
      position: 'relative',
      borderRadius: 8,
      marginBottom: 16,
      fontFamily: 'Roboto, sans-serif',
      backgroundColor: 'rgb(33, 150, 243)',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'justify',
      padding: 8,
    },
  })
);

export enum FilterType {
  SEARCH = 'search',
  CATEGORY = 'category',
  PRODUCT_LINE = 'productLine',
  BRAND = 'brand',
  SUPPLIER = 'supplier',
}

export const getFilterTypeFromDynamicString = (s: string): FilterType | undefined => {
  // Check if the string is a valid enum member
  if (Object.values(FilterType).includes(s as FilterType)) {
    return s as FilterType;
  }
  // Return undefined if the string is not a valid enum member
  return undefined;
};

interface IProps {
  id: string;
  filterType: FilterType;
}

export const findCategory = (categories: ITreeCategory[], id: string) => {
  for (let i = 0; i < categories.length; i++) {
    const subRes = findCurrentCategoryRec(categories[i], id);
    if (subRes !== undefined) return subRes;
  }
};

const findCurrentCategoryRec = (root: ITreeCategory, id: string): ITreeCategory | undefined => {
  if (root.id === id) return root;
  if (root.subCategories === undefined) return undefined;
  for (let i = 0; i < root.subCategories.length; i++) {
    const subRes = findCurrentCategoryRec(root.subCategories[i], id);
    if (subRes !== undefined) return subRes;
  }
  return undefined;
};

const categoriesThatRequireAgeVerification = [12]; // TODO: make this dynamic from administration (category property?)

export default function Offer({ id, filterType }: IProps) {
  const [category, setCategory] = useState<ITreeCategory | undefined>(undefined);
  const [topProduct, setTopProduct] = useState<IProduct | undefined>(undefined);
  const [connectedCategory, setConnectedCategory] = useState<ICategory | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [ageConfirmed, setAgeConfirmed] = useState(
    JSON.parse(sessionStorage.getItem('ageVerified') || 'false') as boolean
  );

  const [loadedProducts, setProducts] = useState<{
    [index: string]: IProduct[];
  }>({});
  const [stamps, setStamps] = useState<IProduct[]>([]);
  const [areProductsLoaded, setAreProductsLoaded] = useState(false);
  const [sort, setSort] = useState('relevance');
  const [shown, setShown] = useState(1);
  const [filterFnc] = useState<(prd: IProduct) => boolean>(() => () => true);

  const { width } = useWindowDimensions();

  const classes = useStyles();
  const theme = useTheme();
  const layout = useLayout();
  const location = useLocation();

  const history = useHistory();

  const changeAgeConfirmed = (newVal: boolean) => {
    sessionStorage.setItem('ageVerified', JSON.stringify(newVal));
    setAgeConfirmed(newVal);
  };

  const fields = `${qualityLabels
    .map((l) => l.name)
    .join(
      ','
    )},age,mass,formId,id,images,title,subtitle,shortDescription,reccomendedPrice,reccomendedPricere,heurekaPrice,zboziPrice,ammount,unit,characteristics,totalPrice,actionPrice,relevanceScore,maxCartAmount,stockAmmount,expiration,pharmasStock,sellLimit,sukl,unavailable,customHeurekaPrice,customZboziPrice,isRegulated,reccomendedAlternative,customExpiration,productMass,isCzech,pharmosStockState,stockState,isRx`;

  useEffect(() => {
    setProducts({});
    setStamps([]);
    setAreProductsLoaded(false);
    setShown(1);
    setPage(0);
    catalogService.get(
      'products/noauth/filter/stamp',
      { filterType: filterType, filterValue: id, fields: fields },
      {
        success: (data: IProduct[]) => {
          setCorrectVerifyLogos(layout.setSuklVerifyLogoVisible, layout.setVeterinaryLogoVisible, data);
          setStamps(data);
          setAreProductsLoaded(true);
        },
        error: () => layout.error('Při načítání produktů došlo k chybě.'),
      }
    );
    catalogService.get(
      'products/noauth/filter',
      {
        filterType: filterType,
        filterValue: id,
        trackHistoryDomain: location.hash.toLowerCase() === '#notrack' ? '' : Environment.domain,
        fields: `${fields},freeDelivery,present,mixDiscount,secondItemDiscount,specialDiscount,crossDiscount`,
      },
      {
        success: (data: IProduct[]) => {
          setCorrectVerifyLogos(layout.setSuklVerifyLogoVisible, layout.setVeterinaryLogoVisible, data);
          setProducts({
            ...loadedProducts,
            [`${filterType}--${id}`]: data.sort(getKeyExtractorReversedComparer('relevanceScore')),
          });
          setAreProductsLoaded(true);
        },
        error: () => layout.error('Při načítání produktů došlo k chybě.'),
      }
    );

    if (filterType === FilterType.CATEGORY) {
      loadCategories((cats) => {
        const cat = findCategory(cats, id);
        if (cat?.id.startsWith('06')) layout.setVeterinaryLogoVisible(true);
        setCategory(cat);
        setConnectedCategory(cat?.nextCategoryId === undefined ? undefined : findCategory(cats, cat.nextCategoryId));
        if ((cat?.topProductId || 0) > 0) {
          catalogService.get(
            '/products/noauth',
            { id: cat?.topProductId },
            {
              success: setTopProduct,
              error: () => setTopProduct(undefined),
            }
          );
        }
      });
    } else {
      setCategory(undefined);
      setTopProduct(undefined);
      setConnectedCategory(undefined);
    }
    // eslint-disable-next-line
  }, [id, filterType]);

  const sortComparer =
    sort === 'relevance'
      ? getKeyExtractorReversedComparer('relevanceScore')
      : sort === 'name'
      ? getKeyExtractorComparer('title')
      : sort === 'priceDesc'
      ? (a: IProduct, b: IProduct) =>
          getPrice(b, layout.homeConfig.todaysSpecial) - getPrice(a, layout.homeConfig.todaysSpecial)
      : sort === 'price'
      ? (a: IProduct, b: IProduct) =>
          getPrice(a, layout.homeConfig.todaysSpecial) - getPrice(b, layout.homeConfig.todaysSpecial)
      : sort === 'unitPriceDesc'
      ? (a: IProduct, b: IProduct) =>
          getUnitPrice(b, layout.homeConfig.todaysSpecial) - getUnitPrice(a, layout.homeConfig.todaysSpecial)
      : sort === 'unitPrice'
      ? (a: IProduct, b: IProduct) =>
          getUnitPrice(a, layout.homeConfig.todaysSpecial) - getUnitPrice(b, layout.homeConfig.todaysSpecial)
      : getKeyExtractorComparer('id');

  const currentLoadedProducts = Object.keys(loadedProducts).includes(`${filterType}--${id}`)
    ? loadedProducts[`${filterType}--${id}`]
    : []; // TODO: remove this variable when refactoring - There was a problem, that when were loading products and then clicked on another category, old proudcts kept fetching and were displayed on second category. We need to cancel HTTP request.
  const products = stamps.length > currentLoadedProducts.length ? stamps : currentLoadedProducts;
  const filteredProductsUnordered = products.filter((prd) => filterFnc(prd));
  const filteredProducts =
    filterType === FilterType.SEARCH ? filteredProductsUnordered : filteredProductsUnordered.sort(sortComparer);

  const productsPerPage =
    width <= theme.breakpoints.width('md') ? 20 : width <= theme.breakpoints.width('lg') ? 21 : 20;
  const productsPerRow = width <= theme.breakpoints.width('md') ? 2 : width <= theme.breakpoints.width('lg') ? 3 : 4;

  return (
    <Container maxWidth="lg">
      {category && <HeadMap currentCategory={category} />}
      <EText variant="h4">
        {category?.description}
        {currentLoadedProducts.length > 0 && (category?.description?.length || 0) > 0 ? ` (${products.length})` : ''}
      </EText>
      {filterType === FilterType.CATEGORY &&
      !ageConfirmed &&
      categoriesThatRequireAgeVerification.find((c) => id.startsWith(c.toString())) !== undefined ? (
        <>
          <AgeVerification
            onAgeConfirm={() => changeAgeConfirmed(true)}
            onAgeReject={() => {
              changeAgeConfirmed(false);
              history.push('/');
            }}
          />
        </>
      ) : (
        <>
          {category && (
            <>
              <CategoriesSuggestion category={category} />

              <br />
              <br />
              {/* <Filters setFilterFunction={setFilterFnc} categoryId={category.id} /> //TODO: moved to CategoryOffer*/}
              <br />
              <br />
            </>
          )}
          {category && filteredProducts.length === products.length && (
            <>
              <Grid
                container
                alignItems={
                  category.topProductId && (products.length === 0 || products.length > 2) ? 'flex-end' : 'flex-start'
                }
                spacing={3}
              >
                <Grid item xs={12} md={category.topProductId ? 6 : 12}>
                  <TopProductCard product={topProduct} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <EText variant="h6">Nejprodávanější produkty</EText>
                  <TopSoldProdCard product={products[0]} />
                  {(products.length > 1 || products.length === 0) && <TopSoldProdCard product={products[1]} />}
                  {(products.length > 2 || products.length === 0) && <TopSoldProdCard product={products[2]} />}
                </Grid>
              </Grid>
              <br />
              <br />
            </>
          )}
          {filterType !== FilterType.SEARCH && (
            <div className={classes.sortRow}>
              {!category && (
                <EText variant="h6">
                  {filterType === FilterType.SUPPLIER
                    ? 'Dodavatel: '
                    : filterType === FilterType.BRAND
                    ? 'Značka: '
                    : filterType === FilterType.PRODUCT_LINE
                    ? 'Produktová řada: '
                    : ''}
                  {id}
                </EText>
              )}
              <div className={classes.flexGrow}>&nbsp;</div>
              <EText>Řazení: </EText>&nbsp;&nbsp;&nbsp;
              <ESelect
                options={[
                  { key: 'relevance', value: 'Relevance' },
                  { key: 'name', value: 'Název' },
                  { key: 'priceDesc', value: 'Od nejdražšího' },
                  { key: 'price', value: 'Od nejlevnějšího' },
                  {
                    key: 'unitPriceDesc',
                    value: 'Od nejvyšší ceny za jednotku',
                  },
                  { key: 'unitPrice', value: 'Od nejnižší ceny za jednotku' },
                ]}
                value={sort}
                onChange={setSort}
              />
            </div>
          )}
          {filterType === FilterType.SEARCH && <SearchedCategories searchedText={id} />}
          <br />
          <br />
          {(!areProductsLoaded && <EText>Produkty se načítají</EText>) || (
            <Grid container>
              {filteredProducts
                .slice(shown * productsPerPage * page, shown * productsPerPage * (page + 1))
                .map((prd, i) => (
                  <React.Fragment key={prd.id}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <ProductPreviewCard product={prd} />
                    </Grid>
                    {category && category.banner && category.banner.length && i === 2 * productsPerRow - 1 && (
                      <Grid item xs={12}>
                        <img
                          style={{
                            width: 'calc(100% - 32px)',
                            paddingBottom: 16,
                          }}
                          alt="banner"
                          src={category.banner}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          )}
          <br />
          {areProductsLoaded && (
            <Button style={{ float: 'left' }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
              Nahoru
            </Button>
          )}
          {shown * productsPerPage < filteredProducts.length && (
            <>
              <div style={{ textAlign: 'right', width: '100%' }}>
                <RightPagination
                  count={Math.ceil(filteredProducts.length / (shown * productsPerPage))}
                  variant="outlined"
                  page={Math.min(page + 1, Math.ceil(filteredProducts.length / (shown * productsPerPage)))}
                  onChange={(e, p) => {
                    setPage(p - 1);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                  color="secondary"
                  shape="rounded"
                  hidePrevButton
                  hideNextButton
                  disabled={currentLoadedProducts.length === 0}
                />
              </div>
              {page === 0 && (
                <div className={classes.center}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    disabled={currentLoadedProducts.length === 0}
                    onClick={() => setShown(Math.min(shown + 1, filteredProducts.length))}
                  >
                    Načíst další produkty
                  </Button>
                </div>
              )}
              <br />
              <br />
            </>
          )}

          {currentLoadedProducts.length > 0 ? ( // TODO: if loaded all products and not just stamps
            <>
              <br /> <br />
              {connectedCategory && shown * productsPerPage * (page + 1) >= filteredProducts.length && (
                <>
                  <br />
                  <div className={classes.productNotFoundPanel}>
                    <EText color="contrast">
                      Nenašli jste produkt, který jste hledali? Podívejte se do kategorie{' '}
                      <a style={{ color: '#ffffff' }} href={`/offer/category/${connectedCategory.id}`}>
                        {connectedCategory.description}
                      </a>
                      .
                    </EText>
                  </div>
                  <br />
                  <br />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Container>
  );
}
