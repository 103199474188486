import React, { useEffect } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import Offer, { FilterType, getFilterTypeFromDynamicString } from './Pages/Offer/Offer';
import ProductDetails from './Pages/ProductDetails';
import FinnishOrder from './Pages/FinnishOrder/FinnishOrder';
import MyOrders from './Pages/Profile/MyOrders';
import Profile from './Pages/Profile/Profile';
import DeliveryAndPayment from './Pages/DeliveryAndPayment';
import StaticSite from './Pages/StaticSite';
import OrderCreatedConfirm from './Pages/OrderCreatedConfirm';
import Substances from './Pages/Substances';
import Error404 from './Errors/Error404';
import { useLayout } from './Layout/LayoutContext';
import ScrollToTop from './Layout/ScrollToTop';
import PaymentCancelled from './Errors/PaymentCancelled';
import Order from './Pages/Order';
import ConfirmPickupPlaceMail from './Pages/ConfirmPickupPlaceMail';
import useCart, { CartState } from './Stores/cartStore';
import PaginatedOffer from './Pages/Offer/PaginatedOffer';
import CookieConsentBanner from './Components/CookieConsentBanner';
import PrivacySettings from './Pages/PrivacySettings';

interface OfferParams {
  id: string;
  filterType: string;
}

interface IdTokenParams {
  id: string;
  token: string;
}

interface IdOnlyParams {
  id: string;
}

interface OfferProps extends RouteComponentProps<OfferParams> {}
interface DetailsProps extends RouteComponentProps<IdOnlyParams> {}
interface StaticSiteProps extends RouteComponentProps<IdOnlyParams> {}
interface OrderCreatedProps extends RouteComponentProps<IdOnlyParams> {}
interface SubstancesProps extends RouteComponentProps<IdOnlyParams> {}
interface OrderProps extends RouteComponentProps<IdOnlyParams> {}
interface MailConfirmProps extends RouteComponentProps<IdTokenParams> {}

export default function App() {
  const clearStoreIfExpired = useCart((state: CartState) => state.setStoreExpireAndClearIfOld);
  const layout = useLayout();
  const validLeaflet = layout.homeConfig.leaflets?.filter(
    (l) =>
      (l.validFrom === undefined || l.validFrom === null || new Date(l.validFrom).getDate() <= new Date().getDate()) &&
      (l.validTo === undefined || l.validTo === null || new Date(l.validTo).getDate() >= new Date().getDate())
  )[0];

  useEffect(() => {
    clearStoreIfExpired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollToTop>
      <Switch>
        <Route
          path="/"
          exact
          render={() => {
            layout.changeLocation(window.location.href);
            return <Home />;
          }}
        />
        <Route
          path="/offer/:filterType(category|supplier|brand|productLine)/:id"
          exact
          render={({ match }: OfferProps) => {
            layout.changeLocation(window.location.href);
            return (
              <PaginatedOffer
                id={match.params.filterType === 'category' ? match.params.id.split('-').reverse()[0] : match.params.id}
                filterType={getFilterTypeFromDynamicString(match.params.filterType) ?? FilterType.CATEGORY} //TODO: show error when filterType not in Enum
              />
            );
          }}
        />

        <Route
          path="/offer/:filterType/:id"
          exact
          render={({ match }: OfferProps) => {
            layout.changeLocation(window.location.href);
            return (
              <Offer
                id={match.params.filterType === 'category' ? match.params.id.split('-').reverse()[0] : match.params.id}
                filterType={getFilterTypeFromDynamicString(match.params.filterType) ?? FilterType.CATEGORY} //TODO: show error when filterType not in Enum
              />
            );
          }}
        />
        <Route
          path="/product/:id"
          exact
          render={({ match }: DetailsProps) => {
            layout.changeLocation(window.location.href);
            return <ProductDetails productId={parseInt(match.params.id.split('-').reverse()[0])} />;
          }}
        />
        <Route
          path="/MyOrders"
          exact
          render={() => {
            layout.changeLocation(window.location.href);
            return <MyOrders />;
          }}
        />
        <Route
          path="/Order/:id"
          exact
          render={({ match }: OrderProps) => {
            layout.changeLocation(window.location.href);
            return <Order orderId={parseInt(match.params.id)} />;
          }}
        />
        <Route
          path="/Profile"
          exact
          render={() => {
            layout.changeLocation(window.location.href);
            return <Profile />;
          }}
        />
        <Route
          path="/FinnishOrder"
          exact
          render={() => {
            layout.changeLocation(window.location.href);
            return <FinnishOrder />;
          }}
        />
        <Route
          path="/DeliveryAndPayment"
          exact
          render={() => {
            layout.changeLocation(window.location.href);
            return <DeliveryAndPayment />;
          }}
        />
        <Route
          path="/PrivacySettings"
          exact
          render={() =>{
            layout.changeLocation(window.location.href);
            return <PrivacySettings />
          }}
        />
        <Route
          path="/staticSite/:id"
          exact
          render={({ match }: StaticSiteProps) => {
            layout.changeLocation(window.location.href);
            return <StaticSite id={parseInt(match.params.id)} />;
          }}
        />
        <Route
          path="/orderCreated/:id"
          exact
          render={({ match }: OrderCreatedProps) => {
            layout.changeLocation(window.location.href);
            return <OrderCreatedConfirm orderId={parseInt(match.params.id)} />;
          }}
        />
        <Route
          path="/substances/:id"
          exact
          render={({ match }: SubstancesProps) => {
            layout.changeLocation(window.location.href);
            return <Substances productId={parseInt(match.params.id)} />;
          }}
        />
        <Route
          path="/paymentCancelled"
          exact
          render={() => {
            layout.changeLocation(window.location.href);
            return <PaymentCancelled />;
          }}
        />
        <Route path="/promotion" exact render={() => validLeaflet && (window.location.href = validLeaflet?.file)} />
        <Route
          path="/PickupPlaces/cofirm/:id/:token"
          exact
          render={({ match }: MailConfirmProps) => {
            layout.changeLocation(window.location.href);
            return <ConfirmPickupPlaceMail id={parseInt(match.params.id)} token={parseInt(match.params.token)} />;
          }}
        />
        <Route
          render={() => {
            layout.changeLocation(window.location.href);
            return <Error404 />;
          }}
        />
      </Switch>
      <CookieConsentBanner />
    </ScrollToTop>
  );
}
