import React, { useEffect, useState } from 'react';
import EText from './EText';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import env from '../Environments';
import { round, separateThousands } from '../Utils/Common';
import { getPrice } from '../Utils/Product';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { useHistory } from 'react-router-dom';
import { useLayout } from '../Layout/LayoutContext';
import CartButton from './CartButton';
import LabelsContainer from './LabelsContainer';
import { catalogService } from '../Utils/ApiService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
    stockAvailabilityContainer: {
      textAlign: 'center',
    },
    availableText: {
      fontWeight: 'bold',
      color: 'green',
    },
    unavailableText: {
      fontWeight: 'bold',
      color: 'red',
    },
    paper: {
    },
    image: {
      width: '40%',
      marginLeft: '30%',
    },
    cartButton: {
      left: 'calc(50% - 66px)',
    },
    description: {
      paddingleft: 8,
      paddingRight: 8,
    },
    unitPrice: {
      fontSize: '0.75rem',
      fontFamily: 'Roboto',
      color: 'grey',
      textAlign: 'center',
    },
  })
);

enum ProductType {
  DEFAULT_PRODUCT = 0,
  ALTERNATIVE_PRODUCT = 1,
}

interface IProps {
  alternativeModalOpen: boolean;
  setAlternativeModalOpen: (val: boolean) => void;
  product: IProduct;
  priceType: string;
  minPriceIn30Days: number;
  alternative: IProduct;
  pickupPlaces: PickupPlace[];
}

const AlternativeDialog = ({
  alternativeModalOpen,
  setAlternativeModalOpen,
  product,
  alternative,
  pickupPlaces,
  priceType,
  minPriceIn30Days,
}: IProps) => {
  const layout = useLayout();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [alternativeLowestPriceIn30Days, setAlternativeLowestPriceIn30Days] = useState<number>(0);

  const availablePlaces = pickupPlaces.filter(
    (p) => (product?.stockState?.find((ss) => ss.placeId === p.id)?.ammount || 0) > 0
  );

  useEffect(() => {
    catalogService.get(
      "products/noauth/productPriceHistory",
      {
        id: alternative.id, 
        priceType: priceType,
      }, 
      {
        success: (response) => {
          if (response.price != null) {
            setAlternativeLowestPriceIn30Days(response.price);
          }
          else {
            setAlternativeLowestPriceIn30Days(getPrice(alternative, layout.homeConfig.todaysSpecial) || 0);
          }
        },
        error: () => layout.error('Při načítání historie cen došlo k chybě'),
      }
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternative.id]);

  return (
    <>
      <Dialog open={alternativeModalOpen} onClose={() => setAlternativeModalOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <Typography variant="h4" align="center">
              Alternativa. {env.fullName} doporučuje.
            </Typography>
            <EText color="secondary" variant="caption" align="center">
              Ušetřete{' '}
              {separateThousands(
                Math.max(
                  round(
                    (1 -
                      getPrice(alternative, layout.homeConfig.todaysSpecial) /
                        (alternative.ammount || 1) /
                        (getPrice(product, layout.homeConfig.todaysSpecial) / (product.ammount || 1))) *
                      100,
                    0
                  ),
                  0
                )
              )}{' '}
              % díky vhodné alternativě balení nebo získejte kvalitnější produkt
            </EText>
            <IconButton
              style={{ position: 'absolute', top: 12, right: 22 }}
              onClick={() => setAlternativeModalOpen(false)}
            >
              <Cancel fontSize="large" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {[product, alternative].map((p, productType: ProductType) => {
              const price = getPrice(p, layout.homeConfig.todaysSpecial);
              const unitPrice = price / (p.ammount || 1);
              const originalUnitPrice = getPrice(product, layout.homeConfig.todaysSpecial) / (product.ammount || 1);

              return (
                <Grid className={classes.grid} item xs={12} md={6} key={p.id}>
                  <div style={{ position: 'relative' }}>
                    <LabelsContainer
                      product={productType === ProductType.DEFAULT_PRODUCT ? product : alternative}
                      showBottomDescription={false}
                      minPrice30Days={productType === ProductType.DEFAULT_PRODUCT ? minPriceIn30Days : alternativeLowestPriceIn30Days}
                    >
                      <img
                        src={`${
                          (p.images?.length || 0) > 0 ? (p.images as string[])[0] : '/img/noImage.webp'
                        }?size=512`}
                        alt="product preview"
                        className={classes.image}
                      />
                    </LabelsContainer>                    
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <span
                      style={{
                        position: "relative",
                        fontFamily: 'Roboto',
                        color: 'white',
                        textAlign: 'center',
                        backgroundColor:
                          productType === ProductType.DEFAULT_PRODUCT
                            ? theme.palette.primary.main
                            : theme.palette.secondary.main,
                        fontSize: '1.2rem',
                        overflow: 'hidden',
                        borderRadius: 8,
                        padding: 8,
                        width: '50%',
                      }}
                    >
                      {productType === ProductType.DEFAULT_PRODUCT
                        ? 'Produkt z reklamy'
                        : `Produkt o ${separateThousands(
                            Math.max(round((1 - unitPrice / originalUnitPrice) * 100, 0), 0)
                          )} % levnější`}
                    </span>
                  </div>
                  <EText
                    onClick={() =>
                      history.push(
                        `/product/${p.title?.replace(/%/g, '')?.replace(/[ ]/g, '-')}-${p.subtitle
                          ?.replace(/%/g, '')
                          ?.replace(/[ ]/g, '-')}-${p.id}`
                      )
                    }
                    align="center"
                    color="secondary"
                    variant="h6"
                  >
                    {p.title}
                  </EText>
                  <EText align="center" color="primary" variant="subtitle2">
                    {p.subtitle}
                  </EText>
                  <EText className={classes.description} multiline align="center" color="primary" variant="body1">
                    {(p?.activeSubstances || []).join(', ')}
                  </EText>
                  {productType === ProductType.ALTERNATIVE_PRODUCT && (
                    <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'green',
                    }}
                  >
                    <CheckCircle />
                    <EText
                      className={classes.description}
                      style={{ color: 'green' }}
                      multiline
                      align="center"
                      color="primary"
                      variant="body1"
                    >
                      {product.reccomendedAlternative?.description}
                    </EText>
                  </span>
                  )}
                  <EText inline align="center" color="primary" variant="h4">
                    {separateThousands(price)} Kč{' '}
                    {productType === ProductType.DEFAULT_PRODUCT && price < minPriceIn30Days && (
                      <span>
                        <Typography
                          noWrap
                          display="inline"
                          style={{ textDecoration: 'line-through' }}
                          align="center"
                          color="secondary"
                          variant="h6"
                        >
                          {separateThousands(minPriceIn30Days)} Kč
                        </Typography>
                      </span>
                    )}
                    {productType === ProductType.ALTERNATIVE_PRODUCT && price < alternativeLowestPriceIn30Days && (
                      <span>
                        <Typography
                          noWrap
                          display="inline"
                          style={{ textDecoration: 'line-through' }}
                          align="center"
                          color="secondary"
                          variant="h6"
                        >
                          {separateThousands(alternativeLowestPriceIn30Days) + " Kč"}
                        </Typography>
                      </span>
                    )}
                  </EText>
                  <EText className={classes.unitPrice}>
                    {separateThousands(round((price || 0) / (p.ammount || 1), 2))} Kč/{p.unit}
                    {productType === ProductType.ALTERNATIVE_PRODUCT && unitPrice < originalUnitPrice && (
                      <>
                        &nbsp;-&nbsp;levnější&nbsp;o&nbsp;
                        {separateThousands(round((1 - unitPrice / originalUnitPrice) * 100, 0))}
                        &nbsp;%
                      </>
                    )}
                  </EText>
                  {productType === ProductType.DEFAULT_PRODUCT && price < minPriceIn30Days && (
                    <EText align="center" color="primary" variant="body1" style={{color: "grey", fontSize: "0.8rem"}}>Minimální prodejní cena za posledních 30 dní:&nbsp;
                     {separateThousands(minPriceIn30Days)} + " Kč" 
                  </EText>  
                )}
                {productType === ProductType.ALTERNATIVE_PRODUCT && price < alternativeLowestPriceIn30Days && (
                  <EText align="center" color="primary" variant="body1" style={{color: "grey", fontSize: "0.8rem"}}>Minimální prodejní cena za posledních 30 dní:&nbsp;
                     {separateThousands(alternativeLowestPriceIn30Days)} + " Kč"
                  </EText>
                )}
                  <CartButton
                    isRecursive
                    className={classes.cartButton}
                    product={p}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div className={classes.stockAvailabilityContainer}>
                    {
                      product.unavailable 
                      ? 
                        <EText className={classes.unavailableText} style={{ color: 'red' }}>
                          Dočasně nedostupné na e-shopu
                        </EText>
                      : 
                        (
                          (product.stockAmmount || 0) > 0 || 
                          ((product.expiration || '') !== '' && new Date(product.expiration || '').getFullYear() > 2000) || 
                          (product.stockState && product.stockState.some(item => item.ammount > 0)) ||
                          (product.pharmosStockState && product.pharmosStockState > 0)
                        ) ?
                          <EText className={classes.availableText} style={{ color: 'green' }}>
                            Skladem na e-shopu
                          </EText>
                          :
                          <EText className={classes.unavailableText} style={{ color: 'red' }}>
                            Dočasně nedostupné na e-shopu
                          </EText>
                    }  
                    {env.showReservation && (
                      <>
                        {product.pharmasStock && availablePlaces.length > 0 ? (
                          <EText style={{ fontSize: 12, color: 'green' }}>
                            K rezervaci na {p.pharmasStock} {p.pharmasStock === 1 ? 'lékárně' : 'lékárnách'}
                          </EText>
                        ) : (
                          <EText style={{ fontSize: 12, color: 'green' }}>&nbsp;</EText>
                        )}
                      </>
                    )}
                  </div>
                  <EText inline color="primary" variant="caption">
                    {p.characteristics}
                  </EText>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AlternativeDialog;
