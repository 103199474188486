import React, { useEffect, useState } from 'react';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import ShoppingCart from './ShoppingCart';
import { reducers } from '../../Utils/Reducers';
import { useLayout } from '../../Layout/LayoutContext';
import { IProduct, IProfile } from '@magistrmartin/eshop-frontend-shared';
import { DiscountsStatus } from '../../Utils/Discounts';
import { getTotalProductPrice } from '../../Utils/Product';
import TotalSavingsPanel from './TotalSavingsPanel';
import StepCard from '../../Components/StepCard';
import ContactInfo from './ContactInfo';
import DeliveryAndPayment from './DeliveryAndPayment';
import OrderRecapitulation from './OrderRecapitulation';
import { catalogService, imagesService, ordersService } from '../../Utils/ApiService';
import { Order } from '../../Types/order';
import { HomeWork, LocalShipping, Visibility, ShoppingCart as ShoppingCartIcon } from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ICentralPresent,
  ShippingPaymentConfiguration,
  DeliveryInfo,
  OrderedProduct,
} from '@magistrmartin/eshop-frontend-shared';
import Environment from '../../Environments';
import { IStepsInfo } from './IStepsInfo';
import NextPrevButtons from './NextPrevButtons';
import useCart, { CartState } from '../../Stores/cartStore';
import CartItem from '../../Types/cartItem';
import { usePickupPlaces } from '../../Hooks/PickupPlaces';
import { Alert } from '@material-ui/lab';
import { useCookies } from 'react-cookie';
import ProcessingDialog from '../../Components/ProcessingDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {},
    flexGrow: { flexGrow: 1 },
    flex: { display: 'flex', justifyContent: 'space-around' },
  })
);

export type deliveryType = 'personalPickup' | 'packetaHome' | 'packeta' | null;
export type payment = 'bankTransaction' | 'paymentGate' | 'pickup' | 'bankTransactionEmail' | null;
export type packetaInfo = {
  id: number;
  name: string;
  url: string;
};

function totalMass(products: CartItem[]) {
  let calculatedMass = products.reduce((totalMass, { product, amount }) => {
    if (product.productMass !== undefined) {
      let mass = product.productMass * amount;
      totalMass += mass;
    }
    return totalMass;
  }, 0);

  //some products might have 0 weight -> causes problem when order is imported to Packeta
  if (calculatedMass === 0) return 100;
  return calculatedMass;
}

// if total product mass is higher than 14 kg, add additional price
// Zásilkovna has limit of 14kg for one package
function additionalDeliveryPrice(totalMass: number) {
  return Math.ceil((totalMass + 1) / 14000);
}

function calculatePaymentPrice(
  delivery: deliveryType,
  payment: payment,
  isAuthenticated: boolean,
  totalPriceWithoutDelivery: number,
  deliveryPaymentConfig: ShippingPaymentConfiguration | undefined
): number {
  if (delivery === 'personalPickup') {
    return 0;
  }

  if (!payment || !deliveryPaymentConfig) {
    return 0;
  }

  const paymentConfig = deliveryPaymentConfig.paymentPrices[payment];

  if (!paymentConfig) {
    return 0;
  }

  if (isAuthenticated) {
    const freeFromRegistered = paymentConfig.freeFromRegistered || 99999999;
    if (freeFromRegistered <= totalPriceWithoutDelivery) {
      return paymentConfig.priceRegistered || 0;
    }
  }

  const freeFrom = paymentConfig.freeFrom || 99999999;
  if (freeFrom <= totalPriceWithoutDelivery) {
    return 0;
  }

  return paymentConfig.price || 0;
}

function calculateDeliveryPrice(
  delivery: deliveryType,
  isAuthenticated: boolean,
  totalPriceWithoutDelivery: number,
  deliveryPaymentConfig: ShippingPaymentConfiguration | undefined,
  discountsStatus: { isDeliveryFree: () => boolean }
): number {
  if (!delivery || !deliveryPaymentConfig) {
    return 0;
  }

  const shippingConfig = deliveryPaymentConfig.shippingPrices[delivery];

  if (!shippingConfig) {
    return 0;
  }

  if (discountsStatus.isDeliveryFree()) {
    return 0;
  }

  const freeFrom = shippingConfig.freeFrom || 99999999;
  if (freeFrom <= totalPriceWithoutDelivery) {
    return 0;
  }

  if (isAuthenticated) {
    const freeFromRegistered = shippingConfig.freeFromRegistered || 999999999;
    if (freeFromRegistered <= totalPriceWithoutDelivery) {
      return shippingConfig.priceRegistered || 0;
    }
  }

  return shippingConfig.price || 0;
}

function hashSHA(text: string | undefined) {
  if (!text) return null;
  
  const encoder = new TextEncoder();
  const data = encoder.encode(text.trim().toLowerCase());
  
  return window.crypto.subtle.digest("SHA-256", data)
    .then(buffer => {
      const hexCodes = [];
      const view = new DataView(buffer);
      for (let i = 0; i < view.byteLength; i += 4) {
        const value = view.getUint32(i);
        hexCodes.push(value.toString(16).padStart(8, '0'));
      }
      return hexCodes.join('');
    });
}

function splitPhoneNumber(phone: string | undefined): { countryCode: string; localNumber: string } {
  if (!phone) return {
    countryCode: "",
    localNumber: ""
  }

  const match = phone.match(/^\+(\d{1,3})(\d+)$/);

  if (!match) {
    return { 
      countryCode: "", 
      localNumber: phone.replace(/[^0-9]/g, "")
    };
  }

  return {
    countryCode: match[1],    // Group 1: country code (1-3 digits)
    localNumber: match[2]     // Group 2: remaining digits
  };
}

export default function FinishOrder() {
  const classes = useStyles();
  const layout = useLayout();
  const { isAuthenticated } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _setCookie] = useCookies(['marketing']);

  const products: CartItem[] = useCart((state: CartState) => state.getProducts());
  const clearCart = useCart((state: CartState) => state.clear);

  const [allCentralPresents, setAllCentralPresents] = useState<ICentralPresent[]>([]);
  const [delivery, setDelivery] = useState<deliveryType>(null);
  const [payment, setPayment] = useState<payment>(null);
  const [packetaInfo, setPacketaInfo] = useState<packetaInfo | undefined>(undefined);
  const [deliveryDetails, setDeliveryDetails] = useState<IProfile>({});
  const [step, setStep] = useState(0);
  const [deliveryPaymentConfig, setDeliveryPaymentConfig] = useState<ShippingPaymentConfiguration | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const [createOrderLoading, setCreateOrderLoading] = useState(false);
  const [presents, setPresents] = useState<IProduct[]>([]);
  const [doNotUseHeurekaSatisfy, setDoNotUseHeurekaSatisfy] = useState(false);
  const [isContactInfoValid, setIsContactInfoValid] = useState(false);
  const [contactPageVisited, setContactPageVisited] = useState(false);
  const [differentDelivery, setDifferentDelivery] = useState(false);
  const { data: pickupPlaces = [] } = usePickupPlaces();
  const [isSUKLTaxId, setIsSUKLTaxId] = useState<boolean | 'loading'>('loading');

  const containsSUKLProducts = products.find((p) => (p.product.sukl?.length || 0) > 0) !== undefined;

  const changeDeliveryDetails = (newDeliveryDetails: IProfile) => {
    if (
      containsSUKLProducts &&
      (newDeliveryDetails.companyId?.replace(/[\s]*/g, '')?.length || 0) === 8 &&
      newDeliveryDetails.companyId !== deliveryDetails.companyId
    ) {
      setIsSUKLTaxId('loading');
      ordersService.get(
        '/stats/isSUKLTaxId',
        { taxId: newDeliveryDetails.companyId?.replace(/[\s]/g, '') },
        {
          success: setIsSUKLTaxId,
          error: () => setIsSUKLTaxId(false),
        }
      );
    }
    setDeliveryDetails(newDeliveryDetails);
  };

  function zboziConversion(orderId: number) {
    const { countryCode, localNumber } = splitPhoneNumber(deliveryDetails.address?.phone);
    if (cookies.marketing) {
      if (window.sznIVA && window.sznIVA.IS) {
        window.sznIVA.IS.updateIdentities({
            eid: hashSHA(deliveryDetails.email), // email či zahashovaný email
            aid: {
                a1:"Česká Republika", // stát
                a2: deliveryDetails.address?.city, // město
                a3: deliveryDetails.address?.street, // ulice
                a4: deliveryDetails.address?.houseNum, // číslo popisné
                a5: deliveryDetails.address?.zip, // poštovní směrovací číslo
            },
            tid: {
              t1: countryCode,
              t2: hashSHA(localNumber)
            }
        });
      }
      else {
        window.addEventListener('szn:identities:ready', function() {
          window.sznIVA.IS.updateIdentities({
            eid: hashSHA(deliveryDetails.email), // email či zahashovaný email
            aid: {
                a1:"Česká Republika", // stát
                a2: deliveryDetails.address?.city, // město
                a3: deliveryDetails.address?.street, // ulice
                a4: deliveryDetails.address?.houseNum, // číslo popisné
                a5: deliveryDetails.address?.zip, // poštovní směrovací číslo
            },
            tid: deliveryDetails.address?.phone, // telefonní číslo
        });
      })
      }
    }
    else {
      window.sznIVA.IS.clearIdentities(['said', 'secid', 'eid', 'aid', 'tid']);
    }

    var conversionConf = {
      zboziId: 224552, // ID provozovny na Zboží
      orderId: orderId, // Číslo objednávky
      zboziType: 'standard', // Typ měření konverzí Zboží.cz, pro testovací režim uvádějte "sandbox"
      consent: cookies.marketing === true ? 1 : 0, // Souhlas od návštěvníka na odeslání konverzního hitu 0 = nesouhlas
    };

    if (window.rc && window.rc.conversionHit) {
      window.rc.conversionHit(conversionConf);
      console.log("Data were send.")
    } else {
      console.log("Data were not send.");
    }
  }

  const discountsStatus = new DiscountsStatus(
    products,
    allCentralPresents,
    isAuthenticated,
    layout.homeConfig.todaysSpecial
  );

  const expectedPrice = products
    .map((p) => p.amount * (p.product.reccomendedPrice || p.product.actionPrice || 0))
    .reduce(reducers.sum, 0);

  const totalPriceWithoutDelivery = useCart((state: CartState) => state.getCartPrice)(layout.homeConfig.todaysSpecial);

  const paymentPrice = calculatePaymentPrice(
    delivery,
    payment,
    isAuthenticated,
    totalPriceWithoutDelivery,
    deliveryPaymentConfig
  );

  const totalProductMass = totalMass(products);
  const addDeliveryPrice = additionalDeliveryPrice(totalProductMass);

  const deliveryPrice = calculateDeliveryPrice(
    delivery,
    isAuthenticated,
    totalPriceWithoutDelivery,
    deliveryPaymentConfig,
    discountsStatus
  );

  const totalPrice = totalPriceWithoutDelivery + paymentPrice + deliveryPrice * addDeliveryPrice;

  useEffect(
    () => {
      imagesService.get(
        '/text',
        {
          file: Environment.domain.replace('.', '_') + '_shippingPayment.json',
          location: 'config',
        },
        {
          success: (data) => {
            setDeliveryPaymentConfig(data);
            setLoading(true);
          },
          error: () => {
            layout.error('Při načítání cen dopravy a platby došlo k chybě');
            setLoading(false);
          },
        }
      );
      // eslint-disable-next-line
    }, []);

  useEffect(() => {
    catalogService.get(
      '/products/noauth/list',
      {
        ids: discountsStatus.getPresents().map((g) => g.presentId),
      },
      {
        success: setPresents,
        error: console.log,
      }
    );
    // eslint-disable-next-line
  }, [allCentralPresents, isAuthenticated]);

  useEffect(() => {
    catalogService.get(
      '/discounts/centralPresents',
      {},
      {
        success: setAllCentralPresents,
        error: console.log,
      }
    );
    // eslint-disable-next-line
  }, []);

  //TODO replace with Helmet, in Nginx add CORS policy for Zbozi.cz domain
  useEffect(
    () => {
      fetch('https://api.magistrmartin.cz/images/text?file=zbozi.js&location=zboziScript')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch the script: ' + response.statusText);
          }
          return response.text();
        })
        .then((scriptContent) => {
          const script = document.createElement('script');
          script.textContent = scriptContent;
          script.async = false;
          script.type = 'text/javascript';
          document.head.appendChild(script);
        })
        .catch((error) => {
          console.error('Error fetching or executing the script:', error);
        });
  }, []);

  const profileToDeliveryInfo = () => {
    return {
      deliveryType: delivery,
      email: deliveryDetails.email,
      phone: deliveryDetails.address?.phone,
      name: deliveryDetails.address?.name,
      surname: deliveryDetails.address?.surname,
      street: deliveryDetails.address?.street,
      houseNum: deliveryDetails.address?.houseNum,
      zip: deliveryDetails.address?.zip,
      city: deliveryDetails.address?.city,
      company: deliveryDetails.address?.company,
      companyId: deliveryDetails.companyId,
      vat: deliveryDetails.vat,
      delPhone: deliveryDetails.deliveryAddress?.phone,
      delComplany: deliveryDetails.deliveryAddress?.company,
      deliveryPrice: deliveryPrice,
      packetaPaceName: packetaInfo?.name,
      packetaPlaceId: packetaInfo?.id,
      packetaPlaceUrl: packetaInfo?.url,
      ...(differentDelivery
        ? {
            delName: deliveryDetails.deliveryAddress?.name,
            delSurname: deliveryDetails.deliveryAddress?.surname,
            delStreet: deliveryDetails.deliveryAddress?.street,
            delHouseNum: deliveryDetails.deliveryAddress?.houseNum,
            delZip: deliveryDetails.deliveryAddress?.zip,
            delCity: deliveryDetails.deliveryAddress?.city,
          }
        : {}),
    } as DeliveryInfo;
  };

  const mapDataToOrderType = (): Order => {
    return {
      usedDiscountCode: '',
      paymentType: payment || undefined,
      totalPrice: totalPrice,
      totalSavings: expectedPrice - totalPriceWithoutDelivery,
      paymentPrice: paymentPrice,
      status: 'Waiting',
      paid: false,
      remark: '',
      userId: deliveryDetails.userId,
      domain: Environment.domain,
      orderedProductsList: [
        ...products.map((p) => {
          const orderedProduct: OrderedProduct = {
            // for type checking divided into object and return statements
            productId: p.product.id,
            orderId: -1,
            productTitle: p.product.title,
            productSubtitle: p.product.subtitle,
            defaultPrice: p.product.reccomendedPrice,
            finalPrice: getTotalProductPrice(p.amount, p.product, discountsStatus, layout.homeConfig.todaysSpecial),
            totalAmmount: p.amount,
            freeAmmount: discountsStatus.getNumberOfFreeUnits(p.product),
            remark:
              p.product.secondItemDiscount && p.product.secondItemDiscount.discount
                ? `Každý 2. kus o ${p.product.secondItemDiscount.discount.value} ${
                    p.product.secondItemDiscount.discount.factor === 'absolute' ? ' Kč' : '%'
                  } levněji`
                : '',
          };
          return orderedProduct;
        }),
        ...discountsStatus.getPresents().map((p) => {
          const receivedPresent = {
            productId: p.presentId,
            productTitle: presents.filter((pres) => p.presentId === pres.id)[0].title,
            productSubtitle: presents.filter((pres) => p.presentId === pres.id)[0].subtitle,
            defaultPrice: 0,
            finalPrice: 0,
            totalAmmount: p.ammount,
            freeAmmount: p.ammount,
            remark: 'PRESENT',
          };
          return receivedPresent;
        }),
      ],
      deliveryInfo: profileToDeliveryInfo(),
      statusChangeHistoryList: [],
    };
  };

  const createOrder = () => {
    setCreateOrderLoading(true);
    const order = mapDataToOrderType();
    ordersService.post('/noauth/', { doNotRegisterToHeureka: doNotUseHeurekaSatisfy }, order, {
      success: (response) => {
        const { location, orderId } = response;
        if (process.env.REACT_APP_ESHOP_VARIANT === 'patrik') zboziConversion(orderId);
        clearCart();
        //setCreateOrderLoading(false);
        window.location.href = location;
      },
      error: () => {
        layout.error(
          'Při vytváření objednávky došlo k chybě, zkuste to prosím později a případně kontaktujte technickou podporu.'
        );
        setCreateOrderLoading(false);
      },
    });
  };

  const stepsInfo: IStepsInfo[] = [
    {
      element: (
        <ShoppingCart
          totalPrice={totalPriceWithoutDelivery}
          presents={presents}
          products={products}
          discountsStatus={discountsStatus}
          totalProductMass={totalProductMass}
        />
      ),
      prevBtnText: 'ZPĚT - POKRAČOVAT V NÁKUPU',
      nextBtnText: 'DÁLE - DOPRAVA A PLATBA',
      canContinue: products.length > 0 && loading && (deliveryPaymentConfig?.minimalOrderPrice || 0) <= totalPrice,
    },
    {
      element: (
        <DeliveryAndPayment
          places={pickupPlaces}
          products={products.filter((p) => p.amount > 0).map((p) => p.product)}
          discountsStatus={discountsStatus}
          packetaInfo={packetaInfo}
          setPacketaInfo={setPacketaInfo}
          delivery={delivery}
          setDelivery={setDelivery}
          payment={payment}
          setPayment={setPayment}
          totalPrice={totalPriceWithoutDelivery}
          paymentPrice={paymentPrice}
          deliveryPrice={deliveryPrice}
          deliveryPriceMultiplier={addDeliveryPrice}
          config={deliveryPaymentConfig}
        />
      ),
      prevBtnText: 'ZPĚT - NÁKUPNÍ KOŠÍK',
      nextBtnText: 'DÁLE - KONTAKTNÍ ÚDAJE',
      canContinue: delivery !== null && payment !== null,
      warningText: 'Pro pokračování dále vyberte jak způsob dopravy, tak platby.',
    },
    {
      element: (
        <ContactInfo
          deliveryDetails={deliveryDetails}
          setIsValid={setIsContactInfoValid}
          setDeliveryDetails={changeDeliveryDetails}
          deliveryType={delivery}
          paymentType={payment}
          contactPageVisited={contactPageVisited}
          setContactPageVisited={setContactPageVisited}
          differentDelivery={differentDelivery}
          setDifferentDelivery={setDifferentDelivery}
        />
      ),
      prevBtnText: 'ZPĚT - DOPRAVA A PLATBA',
      nextBtnText: 'REKAPITULACE OBJEDNÁVKY',
      canContinue:
        isContactInfoValid &&
        (!containsSUKLProducts ||
          (deliveryDetails.companyId?.replace(/[\s]*/g, '')?.length || 0) !== 8 ||
          isSUKLTaxId === false),
    },
    {
      element: (
        <OrderRecapitulation
          presents={presents}
          products={products}
          deliveryDetails={deliveryDetails}
          deliveryType={delivery}
          paymentType={payment}
          discountsStatus={discountsStatus}
          packetaInfo={packetaInfo}
          deliveryPrice={deliveryPrice}
          paymentPrice={paymentPrice}
          totalPrice={totalPrice}
          deliveryPaymentConfig={deliveryPaymentConfig}
          doNotUseHeurekaSatisfy={doNotUseHeurekaSatisfy}
          setDoNotUseHeurekaSatisfy={setDoNotUseHeurekaSatisfy}
          differentDelivery={differentDelivery}
        />
      ),
      prevBtnText: 'ZPĚT - KONTAKTNÍ ÚDAJE',
      nextBtnText: 'ODESLAT OBJEDNÁVKU',
      canContinue: true,
    },
  ];

  return (
    <>
    <ProcessingDialog open={createOrderLoading} />
    <Container maxWidth="lg">
      <div className={classes.flex}>
        <StepCard
          icon={ShoppingCartIcon}
          selected={step >= 0}
          finished={step > 0}
          text="Nákupní košík"
          onClick={step === 0 ? undefined : () => setStep(0)}
        />
        <StepCard
          icon={LocalShipping}
          selected={step >= 1}
          finished={step > 1}
          text="Doprava a platba"
          onClick={
            step < 0
              ? undefined
              : step === 0
              ? stepsInfo[step].canContinue
                ? () => setStep(1)
                : undefined
              : () => setStep(1)
          }
        />
        <StepCard
          icon={HomeWork}
          selected={step >= 2}
          finished={step > 2}
          text="Kontaktní údaje"
          onClick={
            step < 1
              ? undefined
              : step === 1
              ? stepsInfo[step].canContinue
                ? () => setStep(2)
                : undefined
              : () => setStep(2)
          }
        />
        <StepCard
          icon={Visibility}
          selected={step >= 3}
          text="Rekapitulace objednávky"
          onClick={
            step < 2
              ? undefined
              : step === 2
              ? stepsInfo[step].canContinue
                ? () => setStep(3)
                : undefined
              : () => setStep(3)
          }
          isLast
        />
      </div>
      <br />
      <br />
      <br />
      <NextPrevButtons
        createOrder={createOrder}
        createOrderLoading={createOrderLoading}
        setStep={setStep}
        step={step}
        stepsInfo={stepsInfo}
        position={false}
      />
      <br />
      {(deliveryPaymentConfig?.minimalOrderPrice || 0) > totalPrice && (
        <Alert severity="warning" variant="filled">
          Minimální hodnota objednávky je {deliveryPaymentConfig?.minimalOrderPrice || 0} Kč, vyberte si, prosím, další
          produkt.
        </Alert>
      )}
      <br />
      {stepsInfo[step].element}
      {containsSUKLProducts &&
        deliveryDetails.companyId?.replace(/[\s]*/g, '')?.length === 8 &&
        isSUKLTaxId === true && (
          <Alert severity="warning" variant="filled">
            Dle zadaného IČ se jedná o lékárnu nebo prodejce vyhrazených léčiv schválených Státním ústavem pro kontrolu
            léčiv (SÚKL). Z toho důvodu nemůžeme dodat produkty s kódem SÚKL. Děkujeme za pochopení.
          </Alert>
        )}
      <br />
      <TotalSavingsPanel expectedPrice={expectedPrice} actualPrice={totalPriceWithoutDelivery} />
      <br />
      <NextPrevButtons
        createOrder={createOrder}
        createOrderLoading={createOrderLoading}
        setStep={setStep}
        step={step}
        stepsInfo={stepsInfo}
        position={true}
      />
      <br />
      <br />
      <br />
      <br />
    </Container>
    </>
  );
}
