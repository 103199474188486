import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { LayoutProvider } from './Layout/LayoutContext';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import ReactGA from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CookiesProvider } from 'react-cookie';

const defaultTheme = createTheme({
  palette: {
    primary: { main: '#55328B', contrastText: '#ffffff' },
    secondary: { main: '#E2256F', contrastText: '#ffffff' },
  },
});
const { breakpoints } = defaultTheme;

const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '6rem',
        [breakpoints.down('xs')]: {
          fontSize: '3.75rem',
        },
      },
      h2: {
        fontSize: '3.75rem',
        [breakpoints.down('xs')]: {
          fontSize: '3rem',
        },
      },
      h3: {
        fontSize: '3rem',
        [breakpoints.down('xs')]: {
          fontSize: '2.125rem',
        },
      },
      h4: {
        fontSize: '2.125rem',
        [breakpoints.down('xs')]: {
          fontSize: '1.5rem',
        },
      },
      h5: {
        fontSize: '1.5rem',
        [breakpoints.down('xs')]: {
          fontSize: '1.25rem',
        },
      },
      h6: {
        fontSize: '1.25rem',
        [breakpoints.down('xs')]: {
          fontSize: '1rem',
        },
      },
    },
  },
};

const helmetContext = {};
const queryClient = new QueryClient();

ReactGA.initialize('G-PLF1QNSKMN');

ReactDOM.render(
  <Auth0Provider
    domain="login.magistrmartin.cz"
    clientId="FptmnH13aKxwtEFWgNiRP2R5YXxSgqr6"
    redirectUri={`${window.location.origin}/Profile`}
    cacheLocation="localstorage"
  >
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <LayoutProvider>
              <HelmetProvider context={helmetContext}>
                  <App />
              </HelmetProvider>
            </LayoutProvider>
          </BrowserRouter>
        </MuiThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CookiesProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
