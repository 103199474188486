import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import EText from './EText';
import LabelsContainer from './LabelsContainer';
import { getPrice } from '../Utils/Product';
import CartButton from './CartButton';
import { useHistory } from 'react-router-dom';
import Environment from '../Environments';
import { useLayout } from '../Layout/LayoutContext';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reccomendedPanel: {
      width: '100%',
      borderRadius: 8,
      marginTop: 32,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      position: 'relative',
      cursor: 'pointer',
      textTransform: 'none',
    },

    reccomendedHead: {
      paddingTop: 8,
      paddingBottom: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      backgroundColor: theme.palette.primary.main,
    },
    reccomendedBody: {
      paddingLeft: 18,
      paddingRight: 12,
      paddingTop: 12,
      paddingBottom: 12,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      textAlign: 'left',
    },
    recommendedTextPart: {
      paddingLeft: '10px',
    },

    reccomendedImage: {
      width: '100%',
      left: '5%',
    },
    rowWithButton: {
      display: 'flex',
      marginTop: '15px',
      alignItems: 'center',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexGrow: {
      flexGrow: 1,
    },
    price: {
      display: 'flex',
      alignItems: 'center',
    },
    addButton: {
      paddingLeft: '15px',
    },
  })
);

interface IProps {
  product?: IProduct;
  minimalPriceIn30Days: number;
}

const MenuMagistrRecommendsCard = ({ product, minimalPriceIn30Days }: IProps) => {
  const classes = useStyles();
  const history = useHistory();
  const layout = useLayout();

  return product ? (
    <>
      <div
        className={classes.reccomendedPanel}
        onClick={() =>
          history.push(
            `/product/${product.title?.replace(/%/g, '')}-${product.subtitle?.replace(/%/g, '')}-${product.id}`
          )
        }
      >
        <div className={classes.reccomendedHead}>
          <EText variant="h6" color="contrast" align="center">
            {Environment.fullName} doporučuje
          </EText>
        </div>
        <div className={classes.reccomendedBody}>
          <Grid container>
            <Grid xs={6} item>
              <LabelsContainer product={product} showBottomDescription={false} labelsSize={'sm'} minPrice30Days={minimalPriceIn30Days}>
                <img
                  className={classes.reccomendedImage}
                  alt="product logo"
                  src={(product.images?.length || 0) > 0 ? (product.images || [])[0] : '/img/noImage.webp'}
                />
              </LabelsContainer>
            </Grid>
            <Grid xs={6} item className={classes.recommendedTextPart}>
              <EText color="secondary" variant="h6">
                {product.title}
              </EText>
              <EText>{product.subtitle}</EText>
              <div className={classes.rowWithButton}>
                <div className={classes.flexColumn}>
                  {
                    //TODO: try to remove this ts-ignore (i was already burned by this...)
                    //@ts-ignore
                    (getPrice(product) || 0) < (minimalPriceIn30Days || 0) && (
                      <Typography
                        noWrap
                        display="inline"
                        style={{ textDecoration: 'line-through' }}
                        align="center"
                        color="secondary"
                        variant="body1"
                      >
                        {minimalPriceIn30Days} Kč
                      </Typography>
                    )
                  }
                  <EText variant="h6" color="primary" className={classes.price}>
                    {getPrice(product, layout.homeConfig.todaysSpecial)} Kč
                  </EText>
                </div>
                <div className={classes.addButton}>
                  <CartButton ammount={1} product={product} onClick={(e) => e.stopPropagation()} />
                </div>
              </div>

              <br />
              <EText variant="caption">{product.characteristics}</EText>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  ) : (
    <>
      {
        // TODO: loading skeleton
      }
    </>
  );
};

export default MenuMagistrRecommendsCard;
