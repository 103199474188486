import React from 'react';
import { createStyles, Grid, Hidden, makeStyles, Radio, Theme } from '@material-ui/core';
import { DiscountsStatus } from '../../Utils/Discounts';
import EText from '../../Components/EText';
import { deliveryType, packetaInfo, payment } from './FinnishOrder';
import Packeta from '../../Utils/Packeta';
import { useLayout } from '../../Layout/LayoutContext';
import { separateThousands } from '../../Utils/Common';
import { useAuth0 } from '@auth0/auth0-react';
import PlaceSelectMap from '../../Components/PlaceSelectMap';
import { IProduct, ShippingPaymentConfiguration, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import Environment from '../../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    column: {
      padding: 16,
    },
    panelContainer: {
      borderRadius: 8,
      border: '1px solid #DBE1EB',
    },
    panelItemContainer: {
      cursor: 'pointer',
      padding: 18,
    },
    panelItem: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        position: 'relative',
      },
    },
    priceTable: {
      float: 'right',
    },
    iconBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    price: {
      width: 'min-content',
      textWrap: 'nowrap',
    },
    descriptionBlock: {
      padding: 10,
      marginLeft: 20,
    },
    mmPlusAlterContainer: {
      fontSize: '0.8rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      backgroundColor: '#9fc96f',
      color: '#FFFFFF',
      padding: 12,
      borderRadius: 8,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    panelItemSelected: {
      backgroundColor: 'rgb(132, 181, 23)',
      color: 'white',
      borderRadius: '50%',
      width: 32,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      bottom: -12,
      right: -12,
    },
  })
);

interface ICardProps {
  onClick: () => void;
  value: deliveryType | payment;
  expValue: deliveryType | payment;
  iconUrl: string;
  description: string;
  label: string;
  price: string;
  priceAddon?: string;
  mmPlusText?: string;
  last?: boolean;
  disabled?: boolean;
  eco?: boolean;
}

function DeliveryPaymentCard({
  iconUrl,
  description,
  mmPlusText,
  priceAddon,
  label,
  expValue,
  value,
  price,
  onClick,
  last,
  disabled,
  eco,
}: ICardProps) {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      className={classes.panelItemContainer}
      style={{
        ...(disabled ? { filter: 'grayscale(1) opacity(0.5)', cursor: 'initial' } : {}),
        ...(last ? {} : { borderBottom: '1px solid #DBE1EB' }),
        position: 'relative',
      }}
      onClick={disabled ? () => {} : onClick}
    >
      {eco && (
        <img
          style={{ position: 'absolute', top: '5px', right: '0' }}
          src={'/img/eko.svg'}
          alt="eko štítek"
          width={64}
        />
      )}
      <div className={classes.panelItem}>
        <Hidden xsDown>
          <Radio color="primary" checked={expValue === value} />
        </Hidden>
        <div className={classes.iconBlock}>
          <img src={iconUrl} alt="logo" width={86} />
          <EText variant="h6" color="secondary" align="center">
            {price}
          </EText>
          {priceAddon && (
            <div className={classes.price}>
              <EText variant="caption" align="center">
                {priceAddon}
              </EText>
            </div>
          )}
        </div>
        <div className={classes.descriptionBlock}>
          <EText variant="h6" color="secondary">
            {label}
          </EText>
          <EText asDiv variant="caption" multiline align="justify">
            {description}
          </EText>
        </div>
        {expValue === value && (
          <Hidden smUp>
            <div className={classes.panelItemSelected}>✓</div>
          </Hidden>
        )}
      </div>
      {mmPlusText && (
        <div
          className={classes.mmPlusAlterContainer}
          onClick={(e) => {
            e.stopPropagation();
            loginWithRedirect({
              redirectUri: `${window.location.origin}/FinnishOrder`,
            });
          }}
        >
          {mmPlusText}
        </div>
      )}
    </div>
  );
}

interface IProps {
  discountsStatus: DiscountsStatus;
  delivery: deliveryType;
  setDelivery: (del: deliveryType) => void;
  packetaInfo?: packetaInfo;
  setPacketaInfo: (info?: packetaInfo) => void;
  payment: payment;
  setPayment: (pay: payment) => void;
  config?: ShippingPaymentConfiguration;
  totalPrice: number;
  paymentPrice: number;
  deliveryPrice: number;
  places: PickupPlace[];
  products: IProduct[];
  deliveryPriceMultiplier: number;
}

export default function DeliveryAndPayment({
  delivery,
  setDelivery,
  payment,
  setPayment,
  discountsStatus,
  packetaInfo,
  setPacketaInfo,
  config,
  places,
  totalPrice,
  deliveryPrice,
  paymentPrice,
  products,
  deliveryPriceMultiplier,
}: IProps) {
  const classes = useStyles();
  const layout = useLayout();

  const { isAuthenticated } = useAuth0();

  const loadPacketaInfo = () => {
    Packeta.Widget.pick(
      '12fbdb0530c494c9',
      (data: any) => {
        if (data === undefined || data === null) {
          layout.error('Nebylo vybráno žádné výdejní místo, zkuste to prosím znovu.');
          setDelivery('packetaHome');
          setPacketaInfo(undefined);
        } else {
          setPacketaInfo({ id: data.id, name: data.name, url: data.url });
        }
      },
      { version: 6 }
    );
  };

  const getTotalDeliveryPrice = (deliveryType: deliveryType | null, actPrice: number) => {
    if (deliveryType === null) return 0;
    return discountsStatus.isDeliveryFree() || (config?.shippingPrices[deliveryType]?.freeFrom || 99999999) <= actPrice
      ? 0
      : isAuthenticated && (config?.shippingPrices[deliveryType]?.freeFromRegistered || 999999999) <= actPrice
      ? config?.shippingPrices[deliveryType]?.priceRegistered || 0
      : config?.shippingPrices[deliveryType]?.price || 0;
  };

  const getDeliveryPrice = (deliveryType: deliveryType) => getTotalDeliveryPrice(deliveryType, totalPrice);

  const getFreeFrom = (deliveryType: deliveryType | null) => {
    if (deliveryType === null) return -1;
    return isAuthenticated
      ? (config?.shippingPrices[deliveryType]?.freeFromRegistered || -1) < totalPrice
        ? config?.shippingPrices[deliveryType]?.freeFrom || -1
        : config?.shippingPrices[deliveryType]?.freeFromRegistered || -1
      : config?.shippingPrices[deliveryType]?.freeFrom || -1;
  };

  const isMMPlusRelevant = (deliveryType: deliveryType | null) => {
    if (deliveryType === null) return false;
    return (
      !isAuthenticated &&
      getDeliveryPrice(deliveryType) > 0 &&
      (config?.shippingPrices[deliveryType]?.freeFromRegistered || 0) > 0
    );
  };

  const getPaymentPrice = (payment: payment | null) => {
    if (payment === null) return -1;
    return delivery === 'personalPickup'
      ? 0
      : (config?.paymentPrices[payment]?.freeFrom || 99999999) <= totalPrice
      ? 0
      : config?.paymentPrices[payment]?.price || 0;
  };

  const getPaymentFreeFrom = (payment: payment | null) => {
    if (payment === null) return -1;
    return isAuthenticated
      ? (config?.paymentPrices[payment]?.freeFromRegistered || -1) < totalPrice
        ? config?.paymentPrices[payment]?.freeFrom || -1
        : config?.paymentPrices[payment]?.freeFromRegistered || -1
      : config?.paymentPrices[payment]?.freeFrom || -1;
  };

  const isPaymentMMPlusRelevant = (payment: payment | null) => {
    if (payment === null) return false;
    return getPaymentPrice(payment) > 0 && (config?.paymentPrices[payment]?.freeFromRegistered || 0) > 0;
  };

  const placesMissing = places.filter(
    (place) => products.find((p) => p.stockState?.find((s) => s.placeId === place.id) === undefined) !== undefined
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.column}>
          <EText variant="h5">Doprava</EText>
          <br />
          <br />
          <div className={classes.panelContainer}>
            {!Environment.disablePharmaReservation && (
              <PlaceSelectMap
                renderOpener={(open) => (
                  <DeliveryPaymentCard
                    description={config?.shippingPrices.personalPickup.description || ''}
                    expValue="personalPickup"
                    value={delivery}
                    onClick={open}
                    iconUrl={config?.shippingPrices.personalPickup.image || ''}
                    label={config?.shippingPrices.personalPickup.label || ''}
                    eco={true}
                    price={
                      getDeliveryPrice('personalPickup') === 0
                        ? 'Zdarma'
                        : `${separateThousands(getDeliveryPrice('personalPickup'))} Kč`
                    }
                    priceAddon={
                      getDeliveryPrice('personalPickup') !== 0 && getFreeFrom('personalPickup') > 0
                        ? `od ${separateThousands(getFreeFrom('personalPickup'))} Kč ${
                            getTotalDeliveryPrice('personalPickup', getFreeFrom('personalPickup') + 1) === 0
                              ? 'ZDARMA'
                              : `za ${getTotalDeliveryPrice('personalPickup', getFreeFrom('personalPickup') + 1)} Kč`
                          }`
                        : undefined
                    }
                    mmPlusText={
                      isMMPlusRelevant('personalPickup')
                        ? `Přihlaste se do klubu Magistr Martin a od ${separateThousands(
                            config?.shippingPrices['personalPickup']?.freeFromRegistered
                          )} Kč získáte dopravu ${
                            config?.shippingPrices['personalPickup']?.priceRegistered === 0
                              ? 'ZDARMA'
                              : 'za ' +
                                separateThousands(config?.shippingPrices['personalPickup']?.priceRegistered) +
                                ' Kč.'
                          }`
                        : undefined
                    }
                  />
                )}
                onSelect={(place) => {
                  setDelivery('personalPickup');
                  setPayment('pickup');
                  setPacketaInfo({
                    id: place.id || -1,
                    name: place.name || '',
                    url: '',
                  });
                }}
                places={places}
                unavailablePlaceIds={placesMissing.map((p) => p.id || -1)}
              />
            )}
            <DeliveryPaymentCard
              description={config?.shippingPrices.packeta.description || ''}
              expValue="packeta"
              value={delivery}
              onClick={() => {
                setDelivery('packeta');
                loadPacketaInfo();
              }}
              iconUrl={config?.shippingPrices.packeta.image || ''}
              label={config?.shippingPrices.packeta.label || ''}
              price={getDeliveryPrice('packeta') === 0 ? 'Zdarma' : `${getDeliveryPrice('packeta')} Kč`}
              eco={true}
              priceAddon={
                getDeliveryPrice('packeta') !== 0 && getFreeFrom('packeta') > 0
                  ? `od ${separateThousands(getFreeFrom('packeta'))} Kč ${
                      getTotalDeliveryPrice('packeta', getFreeFrom('packeta') + 1) === 0
                        ? 'ZDARMA'
                        : `za ${getTotalDeliveryPrice('packeta', getFreeFrom('packeta') + 1)} Kč`
                    }`
                  : undefined
              }
              // mmPlusText={
              //   // isMMPlusRelevant("packeta")
              //   //   ? `Přihlaste se do klubu Magistr Martin a od ${separateThousands(
              //   //       config?.shippingPrices["packeta"]?.freeFromRegistered
              //   //     )} Kč získáte dopravu ${
              //   //       config?.shippingPrices["packeta"]?.priceRegistered === 0
              //   //         ? "ZDARMA"
              //   //         : "za " + separateThousands(config?.shippingPrices["packeta"]?.priceRegistered) + " Kč."
              //   //     }`
              //   //   : undefined
              //   'Upozorňujeme, že v AlzaBoxech své zásilky můžete vyzvedávat zpravidla do půlnoci následujícího dne od doručení zásilky. Z-Boxy a výdejní místa zpravidla nabízejí delší dobu uložení zásilky.'
              // }
            />
            <DeliveryPaymentCard
              description={config?.shippingPrices.packetaHome.description || ''}
              expValue="packetaHome"
              value={delivery}
              onClick={() => {
                setDelivery('packetaHome');
              }}
              iconUrl={config?.shippingPrices.packetaHome.image || ''}
              label={config?.shippingPrices.packetaHome.label || ''}
              price={
                getDeliveryPrice('packetaHome') === 0
                  ? 'Zdarma'
                  : `${separateThousands(getDeliveryPrice('packetaHome'))} Kč`
              }
              priceAddon={
                getDeliveryPrice('packetaHome') !== 0 && getFreeFrom('packetaHome') > 0
                  ? `od ${separateThousands(getFreeFrom('packetaHome'))} Kč ${
                      getTotalDeliveryPrice('packetaHome', getFreeFrom('packetaHome') + 1) === 0
                        ? 'ZDARMA'
                        : `za ${getTotalDeliveryPrice('packetaHome', getFreeFrom('packetaHome') + 1)} Kč`
                    }`
                  : undefined
              }
              mmPlusText={
                isMMPlusRelevant('packetaHome')
                  ? `Přihlaste se do klubu Magistr Martin a od ${separateThousands(
                      config?.shippingPrices['packetaHome']?.freeFromRegistered
                    )} Kč získáte dopravu ${
                      config?.shippingPrices['packetaHome']?.priceRegistered === 0
                        ? 'ZDARMA'
                        : 'za ' + separateThousands(config?.shippingPrices['packetaHome']?.priceRegistered) + ' Kč.'
                    }`
                  : undefined
              }
              last
            />
          </div>
          {(delivery === 'packeta' || delivery === 'personalPickup') && packetaInfo && (
            <>
              <br />
              <EText variant="caption">Zvolené výdejní místo: {packetaInfo.name}</EText>
              <br />
              <EText variant="caption">
                <a href={packetaInfo.url} target="_blank" rel="noopener noreferrer">
                  {packetaInfo.url}
                </a>
              </EText>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6} className={classes.column}>
          <EText variant="h5">Platba</EText>
          <br />
          <br />
          <div className={classes.panelContainer}>
            {
              <DeliveryPaymentCard
                description={config?.paymentPrices.bankTransactionEmail.description || ''}
                expValue="bankTransactionEmail"
                value={payment}
                onClick={() => setPayment('bankTransactionEmail')}
                iconUrl={config?.paymentPrices.bankTransaction.image || ''}
                label={config?.paymentPrices.bankTransactionEmail.label || ''}
                disabled={delivery === 'personalPickup'}
                price={
                  getPaymentPrice('bankTransaction') === 0
                    ? 'Zdarma'
                    : `${separateThousands(getPaymentPrice('bankTransaction'))} Kč`
                }
                priceAddon={
                  getPaymentPrice('bankTransaction') !== 0 && getPaymentFreeFrom('bankTransaction') > 0
                    ? `od ${separateThousands(getPaymentFreeFrom('bankTransaction'))} Kč ZDARMA`
                    : undefined
                }
                mmPlusText={
                  isPaymentMMPlusRelevant('bankTransaction')
                    ? `Přihlaste se do klubu Magistr Martin a od ${separateThousands(
                        config?.paymentPrices['bankTransactionEmail']?.freeFromRegistered
                      )} Kč získáte dopravu ${
                        config?.paymentPrices['bankTransactionEmail']?.priceRegistered === 0
                          ? 'ZDARMA'
                          : 'za ' +
                            separateThousands(config?.paymentPrices['bankTransactionEmail']?.priceRegistered) +
                            ' Kč.'
                      }`
                    : undefined
                }
              />
            }
            <DeliveryPaymentCard
              description={config?.paymentPrices.bankTransaction.description || ''}
              expValue="bankTransaction"
              value={payment}
              onClick={() => setPayment('bankTransaction')}
              iconUrl={config?.paymentPrices.bankTransaction.image || ''}
              label={config?.paymentPrices.bankTransaction.label || ''}
              disabled={delivery === 'personalPickup'}
              price={
                getPaymentPrice('bankTransaction') === 0
                  ? 'Zdarma'
                  : `${separateThousands(getPaymentPrice('bankTransaction'))} Kč`
              }
              priceAddon={
                getPaymentPrice('bankTransaction') !== 0 && getPaymentFreeFrom('bankTransaction') > 0
                  ? `od ${separateThousands(getPaymentFreeFrom('bankTransaction'))} Kč ZDARMA`
                  : undefined
              }
              mmPlusText={
                isPaymentMMPlusRelevant('bankTransaction')
                  ? `Přihlaste se do klubu Magistr Martin a od ${separateThousands(
                      config?.paymentPrices['bankTransaction']?.freeFromRegistered
                    )} Kč získáte dopravu ${
                      config?.paymentPrices['bankTransaction']?.priceRegistered === 0
                        ? 'ZDARMA'
                        : 'za ' + separateThousands(config?.paymentPrices['bankTransaction']?.priceRegistered) + ' Kč.'
                    }`
                  : undefined
              }
            />
            <DeliveryPaymentCard
              description={config?.paymentPrices.paymentGate.description || ''}
              expValue="paymentGate"
              value={payment}
              onClick={() => setPayment('paymentGate')}
              disabled={delivery === 'personalPickup'}
              iconUrl={config?.paymentPrices.paymentGate.image || ''}
              label={config?.paymentPrices.paymentGate.label || ''}
              price={
                getPaymentPrice('paymentGate') === 0
                  ? 'Zdarma'
                  : `${separateThousands(getPaymentPrice('paymentGate'))} Kč`
              }
              priceAddon={
                getPaymentPrice('paymentGate') !== 0 && getPaymentFreeFrom('paymentGate') > 0
                  ? `od ${separateThousands(getPaymentFreeFrom('paymentGate'))} Kč ZDARMA`
                  : undefined
              }
              mmPlusText={
                isPaymentMMPlusRelevant('paymentGate')
                  ? `Přihlaste se do klubu Magistr Martin a od ${separateThousands(
                      config?.paymentPrices['paymentGate']?.freeFromRegistered
                    )} Kč získáte dopravu ${
                      config?.paymentPrices['paymentGate']?.priceRegistered === 0
                        ? 'ZDARMA'
                        : 'za ' + separateThousands(config?.paymentPrices['paymentGate']?.priceRegistered) + ' Kč.'
                    }`
                  : undefined
              }
            />
            <DeliveryPaymentCard
              description={config?.paymentPrices.pickup.description || ''}
              expValue="pickup"
              value={payment}
              onClick={() => setPayment('pickup')}
              iconUrl={config?.paymentPrices.pickup.image || ''}
              label={config?.paymentPrices.pickup.label || ''}
              price={getPaymentPrice('pickup') === 0 ? 'Zdarma' : `${separateThousands(getPaymentPrice('pickup'))} Kč`}
              priceAddon={
                getPaymentPrice('pickup') !== 0 && getPaymentFreeFrom('pickup') > 0
                  ? `od ${separateThousands(getPaymentFreeFrom('pickup'))} Kč ZDARMA`
                  : undefined
              }
              mmPlusText="Pokud by to bylo možné, tak, prosím, plaťte hotově. Pomůžete nám tak ušetřit na poplatku za platbu kartou při dobírce a my tak budeme moci držet nízké ceny produktů."
              last
            />
          </div>
          <br />
          <table className={classes.priceTable}>
            <tbody>
              <tr>
                <td>
                  <EText variant="h6">Cena dopravy a platby</EText>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <EText variant="h6">{separateThousands(deliveryPrice + paymentPrice)} Kč</EText>
                </td>
              </tr>
              {deliveryPrice > 0 && (
                <tr>
                  <td>
                    <EText variant="h6">Cena za nadměrnou zásilku</EText>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <EText variant="h6">
                      {separateThousands(deliveryPrice * deliveryPriceMultiplier - deliveryPrice)} Kč
                    </EText>
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <EText variant="h6">Celková cena s DPH</EText>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <EText variant="h6">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {separateThousands(
                      totalPrice +
                        paymentPrice +
                        deliveryPrice +
                        deliveryPrice * deliveryPriceMultiplier -
                        deliveryPrice
                    )}{' '}
                    Kč
                  </EText>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </>
  );
}
