import { Button, Tooltip, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React from 'react';
import EText from '../../Components/EText';
import { IStepsInfo } from './IStepsInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {},
    flexGrow: { flexGrow: 1 },
    flex: { display: 'flex', justifyContent: 'space-between' },
    warningText: { display: 'flex', justifyContent: 'right' },
    tooltip: {
      fontSize: '0.8rem',
      backgroundColor: '#3299E1',
    },
  })
);

interface Props {
  stepsInfo: IStepsInfo[];
  step: number;
  setStep: (num: number) => void;
  createOrder: () => void;
  createOrderLoading: boolean;
  position: boolean;
}

const NextPrevButtons = ({ stepsInfo, step, setStep, createOrder, createOrderLoading: loading, position }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const lastStepIndex = stepsInfo.length - 1;
  const currentStep = stepsInfo[step];

  // Helper functions for readability
  const handlePrevClick = () => {
    if (step === 0) {
      history.goBack();
    } else {
      setStep(step - 1);
    }
    window.scrollTo({ top: 0, left: 0 });
  };

  const handleNextClick = () => {
    if (step < lastStepIndex) {
      setStep(step + 1);
    } else {
      createOrder();
    }
    window.scrollTo({ top: 0, left: 0 });
  };

  const isDisabled = loading || !currentStep.canContinue;
  const nextButtonStyle = isDisabled
    ? {}
    : {
        backgroundColor: '#84B517',
        color: 'white',
      };

  const renderPrevButton = () => {
    if (!currentStep.prevBtnText) return null;

    return (
      <Button variant="contained" color="primary" onClick={handlePrevClick}>
        {currentStep.prevBtnText}
      </Button>
    );
  };

  const renderNextButton = () => {
    if (!currentStep.nextBtnText) return null;

    if (step === 1 && !currentStep.canContinue) {
      return (
        <Tooltip arrow classes={{ tooltip: classes.tooltip }} title="Prosím, zvolte typ dopravy a platby">
          <span>
            <Button variant="contained" color="secondary" disabled>
              {currentStep.nextBtnText}
            </Button>
          </span>
        </Tooltip>
      );
    }

    return (
      <Button
        variant="contained"
        color="secondary"
        disabled={isDisabled}
        style={nextButtonStyle}
        onClick={handleNextClick}
      >
        {loading ? 'Odesílám zásilku' : currentStep.nextBtnText}
      </Button>
    );
  };

  const renderWarningText = (text: string) => {
    return (
      <div className={classes.warningText}>
        <EText align="right" style={{ fontSize: '14px', color: 'red', float: 'right' }}>
          {text}
        </EText>
      </div>
    );
  };

  const renderOrderWarning = () => {
    if (currentStep.nextBtnText?.toLowerCase() === 'odeslat objednávku') {
      return (
        <div style={{ width: '100%', paddingTop: 8, textAlign: 'right' }}>
          <EText align="right" variant="caption">
            Odesíláte objednávku s povinností převzetí a platby.
          </EText>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {/* {step === 1 && !currentStep.canContinue && position ? renderWarningText() : null} */}
      {position && currentStep.warningText && !currentStep.canContinue && renderWarningText(currentStep.warningText)}
      <div className={classes.flex}>
        {renderPrevButton()}
        <span className={classes.flexGrow}>&nbsp;</span>
        {renderNextButton()}
      </div>
      {/* {step === 1 && !currentStep.canContinue && !position ? renderWarningText() : null} */}
      {!position && currentStep.warningText && !currentStep.canContinue && renderWarningText(currentStep.warningText)}
      {renderOrderWarning()}
    </>
  );
};

export default NextPrevButtons;
